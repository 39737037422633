<template>
  <div class="tooltip" v-show="show">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.tooltip {
  width: min-content;
  background: #ffffff;
  color: inherit;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 170%;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid #ffffff;
  /* border: 1px solid #ff696e; */
}
.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  /* border-color: #ff696e transparent transparent transparent; */
}
</style>

<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { isDonorAuthenticated } from '@/api/auth'

export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'dashboard') + '-layout'
    }
  },
  methods: {
    async logoutDueToBeingUnAuthenticated() {
      this.$store.dispatch('afterSuccessLogin', null)
      this.$storage.removeStorageSync('userData')
      this.$router.go()
    }
  },
  async created() {
    const userInfo = this.$storage.hasKey('userData')
      ? this.$storage.getStorageSync('userData')
      : null

    this.$store.dispatch('afterSuccessLogin', userInfo)
    const routeName =
      window.location.pathname.split('/').length > 0
        ? window.location.pathname.split('/')[1]
        : ''

    const routesWithoutAuthentication = [
      'login',
      'forgot-password',
      'reset-password'
    ]

    if (userInfo === null && !routesWithoutAuthentication.includes(routeName)) {
      this.$router.push('/login')
    } else {
      if (routeName === 'reset-password' || routeName === 'forgot-password') {
        return
      }

      if (routeName !== 'login') {
        const { status } = await isDonorAuthenticated()

        if (status === 401) {
          await this.logoutDueToBeingUnAuthenticated()
        }
      }
    }
  }
}
</script>

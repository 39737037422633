<template>
  <div
    class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
      </div>

      <form class="mt-8 space-y-6" @submit.prevent="resetPassword">
        <div class="flex justify-between mb-[30px] md:mb-2 w-full">
          <BaseInput
            id="password"
            label="New Password"
            type="password"
            v-model="password"
            required
            @keydown.enter.prevent="resetPassword"
            @blur="onBlur"
            :error="this.v$.password.$errors[0]?.$message"
          />
        </div>
        <div class="flex justify-between mb-[30px] md:mb-2 w-full">
          <BaseInput
            id="passwordConfirmation"
            label="Repeat Password"
            type="password"
            v-model="passwordConfirmation"
            required
            @keydown.enter.prevent="resetPassword"
            @blur="onBlur"
            :error="this.v$.passwordConfirmation.$errors[0]?.$message"
          />
        </div>
        <BaseButton
          id="resetPassword"
          :onClick="resetPassword"
          class="py-3"
          :disabled="isDisabled || canProceed"
          :loading="loading"
        >
          <span class="font-medium tracking-1 text-sm leading-15">
            Proceed
          </span>
        </BaseButton>
      </form>
      <div class="text-sm text-center">
        <router-link
          to="/login"
          class="font-medium text-cuddly-600 hover:text-cuddly-500"
        >
          Back to Login
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import { helpers, required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { resetDonorsPassword } from '@/api/auth'

export default {
  name: 'ResetPassword',
  components: {
    BaseButton,
    BaseInput
  },
  methods: {
    onBlur(e) {
      this.v$[e.target.id].$touch()
    },
    async resetPassword() {
      if (this.isDisabled !== true && this.loading !== true) {
        this.v$.password.$touch()
        this.v$.passwordConfirmation.$touch()

        if (
          !this.v$.$validationGroups.password.$invalid &&
          !this.v$.$validationGroups.passwordConfirmation.$invalid
        ) {
          this.isDisabled = true
          this.loading = true

          const { status, data } = await resetDonorsPassword(
            this.token,
            this.password,
            this.passwordConfirmation,
            this.emailHash
          )
          let errorMessage = ''

          if (status === 200) {
            this.$toast.success('Password Reset Successfully')

            setTimeout(() => {
              this.$router.push('/login')
            }, 1500)
          } else if (status === 401) {
            errorMessage = 'The token has expired.'
            this.isDisabled = false
            this.loading = false
          } else {
            errorMessage = 'Whoops, looks like something went wrong.'

            if (Object.hasOwn(data[0], 'password')) {
              errorMessage = data[0].password[0]
            }

            this.isDisabled = false
            this.loading = false
          }

          if (errorMessage.length > 0) {
            this.$toast.error(errorMessage)
          }
        } else {
          this.isDisabled = false
          this.loading = false
        }
      }
    }
  },
  validations: {
    password: {
      required: helpers.withMessage('Password Required.', required)
    },
    passwordConfirmation: {
      required: helpers.withMessage('Password Required.', required)
    },
    $validationGroups: {
      password: ['password'],
      passwordConfirmation: ['passwordConfirmation']
    }
  },
  computed: {
    canProceed() {
      return (
        this.password.length === 0 || this.passwordConfirmation.length === 0
      )
    }
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      v$: useVuelidate(),
      isDisabled: false,
      loading: false,
      emailHash: '',
      token: ''
    }
  },
  async created() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (
      urlParams.has('token') &&
      urlParams.get('token').length > 0 &&
      urlParams.has('hash') &&
      urlParams.get('hash').length > 0
    ) {
      this.emailHash = urlParams.get('hash')
      this.token = urlParams.get('token')
    } else {
      this.$router.push('/login')
    }
  }
}
</script>

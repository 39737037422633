<template>
  <div
    class="relative min-h-screen md:min-h-[693px] w-full md:max-w-[390px] bg-white flex flex-col lg:flex-row top-16 md:shadow-2 md:rounded-2xl md:mx-auto overflow-hidden"
  >
    <div class="flex flex-col w-full h-full items-start">
      <div class="absolute top-0 md:right-0 p-5 pt-2.5 md:pr-5">
        <BaseIcon
          name="arrow-left"
          class="md:hidden h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
          v-if="!canceled && !paused"
        />
        <BaseIcon
          name="close"
          class="hidden md:block h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
        />
      </div>
      <SubscriptionCancelCanceled
        v-if="canceled"
        :subscription="subscription"
        :subscription-type="subscriptionType"
        :club-url="href"
        :is-full-bowls="isFullBowls"
      />
      <SubscriptionCancelPaused
        v-else-if="paused"
        :subscription="subscription"
        :subscription-type="subscriptionType"
        :resumes-at="resumesAt"
        :months="months"
        :club-url="href"
        :club-url-placeholder="urlPlaceholder"
        :is-full-bowls="isFullBowls"
      />
      <SubscriptionCancelFailed v-else-if="failed" :error="error" />
      <SubscriptionCancelCanceling
        v-else-if="isCanceling"
        :subscription="subscription"
        :subscription-type="subscriptionType"
      />
      <SubscriptionCancelDefault
        :subscription="subscription"
        :subscriptionType="subscriptionType"
        v-else
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import date from '@/mixins/date'
import SubscriptionCancelDefault from '@/components/Subscription/SubscriptionCancel/SubscriptionCancelDefault.vue'
import SubscriptionCancelFailed from '@/components/Subscription/SubscriptionCancel/SubscriptionCancelFailed.vue'
import SubscriptionCancelPaused from '@/components/Subscription/SubscriptionCancel/SubscriptionCancelPaused.vue'
import SubscriptionCancelCanceling from '@/components/Subscription/SubscriptionCancel/SubscriptionCancelCanceling.vue'
import SubscriptionCancelCanceled from '@/components/Subscription/SubscriptionCancel/SubscriptionCancelCanceled.vue'
import scrollTop from '@/mixins/scrollTop'

export default {
  name: 'SubscriptionCancel',
  mixins: [date, scrollTop],
  components: {
    SubscriptionCancelCanceling,
    SubscriptionCancelPaused,
    SubscriptionCancelFailed,
    SubscriptionCancelDefault,
    SubscriptionCancelCanceled,
    BaseIcon
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cuddlyClubUrl: `${process.env.VUE_APP_CUDDLY_URL}/club`,
      fullBowlsUrl: `${process.env.VUE_APP_CUDDLY_URL}/fullbowls`,
      months: 0,
      error:
        'Unfortunately we have experienced an error. Please try again later.',
      canceled: false,
      paused: false,
      loading: false,
      failed: false,
      // isCanceling: this.subscription.method === 'paypal',
      isCanceling: false, // this.subscription.method === 'paypal',
      resumesAt: 0
    }
  },
  created() {
    this.scrollTop()
    this.emitter.on('subscription-cancel_canceling', ({ isCanceling }) => {
      this.isCanceling = isCanceling
      this.scrollTop()
    })
    this.emitter.on(
      'subscription-cancel_paused',
      ({ paused, months, resumesAt }) => {
        this.paused = paused
        this.months = months
        this.resumesAt = resumesAt
        this.scrollTop()
      }
    )
    this.emitter.on('subscription-cancel_failed', ({ error }) => {
      this.isCanceling = false
      this.failed = true
      this.error = error || this.error
      this.scrollTop()
    })
    this.emitter.on('subscription-cancel_canceled', ({ canceled }) => {
      this.canceled = canceled
      this.scrollTop()
    })
  },
  methods: {
    handleBack() {
      this.emitter.emit('back-to-subscription', {
        reload: this.canceled || this.paused
      })
    }
  },
  computed: {
    imageSrc() {
      const images = require.context('@/assets', true)
      const image =
        this.subscription.type === 'fullbowls'
          ? 'full-bowls-club-subscriptions-state.png'
          : 'cuddly-club-mutt-scouts.png'

      return images(`./${image}`)
    },
    subscriptionType() {
      return this.subscription.type === 'fullbowls'
        ? 'Full Bowls Club'
        : 'CUDDLY Club'
    },
    isFullBowls() {
      return this.subscription.type === 'fullbowls'
    },
    href() {
      return this.subscription.type === 'fullbowls'
        ? this.fullBowlsUrl
        : this.cuddlyClubUrl
    },
    urlPlaceholder() {
      return this.subscription.type === 'fullbowls'
        ? 'cuddly.com/fullbowls'
        : 'cuddly.com/club'
    }
  }
}
</script>

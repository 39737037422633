<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    stroke="currentColor"
    stroke-width="0"
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2447_25140)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.4715 24.0271C29.5858 24.0886 33.5685 27.6194 35.4887 32.0515C36.6599 34.762 36.4157 37.2586 35.5315 38.8728C34.6539 40.4745 31.9119 40.3157 30.0908 38.9603C28.7018 37.9261 27.1377 37.1674 25.4717 37.0799C23.8022 37.1674 22.2416 37.9228 20.8527 38.9603C19.0315 40.3189 16.2896 40.4776 15.412 38.8728C14.531 37.2583 14.2836 34.7617 15.4548 32.0515C17.3749 27.6193 21.3572 24.0885 25.472 24.0271H25.4715ZM10.7031 18.9694C8.91808 19.822 8.43637 22.5519 9.6274 25.0647C10.8184 27.5772 13.2272 28.9295 15.012 28.0766C16.797 27.224 17.2787 24.494 16.0877 21.9813C14.8967 19.4687 12.485 18.1199 10.7031 18.9694ZM19.407 10.0661C21.7628 9.7289 24.0494 12.1087 24.5145 15.3801C24.9764 18.6546 23.4423 21.5825 21.0833 21.9229C18.7275 22.2601 16.4409 19.8803 15.9758 16.6089C15.5139 13.3344 17.0515 10.4036 19.407 10.0661ZM40.2398 18.9694C38.4548 18.1167 36.0463 19.4687 34.8552 21.9813C33.6642 24.4973 34.1459 27.2272 35.931 28.0766C37.716 28.9292 40.1245 27.5772 41.3155 25.0647C42.5065 22.5519 42.0248 19.8219 40.2398 18.9694ZM31.5359 10.0661C33.8916 10.4033 35.429 13.3343 34.9671 16.6089C34.5052 19.8835 32.2187 22.2633 29.8597 21.9229C27.5039 21.5857 25.9665 18.6548 26.4284 15.3801C26.8901 12.1055 29.1766 9.72572 31.5359 10.0661Z"
        fill="#FF696E"
      />
    </g>
    <circle cx="25" cy="25" r="23.5" stroke="#2A415B" stroke-width="3" />
    <defs>
      <clipPath id="clip0_2447_25140">
        <rect
          width="33.0529"
          height="30.0481"
          fill="white"
          transform="translate(8.97461 9.97559)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IconAnimalsHelped'
}
</script>

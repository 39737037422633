<template>
  <div class="flex flex-col p-5 mt-6">
    <span class="text-brand-navy font-extrabold subtitle-4">
      What's the main reason you canceled your
      {{ subscriptionType }} subscription?
    </span>
    <div
      class="min-h-[220px] mt-3"
      :class="{
        'min-h-[240px]': this.v$.cancelOption.$errors[0]?.$message
      }"
    >
      <BaseRadio
        id="reason"
        :options="cancelOptions"
        v-model="cancelOption"
        variant="secondary"
        orientation="vertical"
        :error="this.v$.cancelOption.$errors[0]?.$message"
      />
    </div>
    <span class="text-brand-navy font-extrabold subtitle-4 mt-3">
      Let us know more about why you are canceling:
    </span>
    <div class="mt-2 mb-4 w-full">
      <BaseTextarea
        id="cancel-reason"
        class="h-[60px]"
        v-model="cancelReason"
        :error="this.v$.cancelReason.$errors[0]?.$message"
      />
    </div>
    <div
      class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
    >
      <BaseButton
        id="save"
        class="h-11 md:text-xs"
        @click="handleCancelSubscription"
        :loading="loading"
        :disabled="loading"
      >
        Continue
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseTextarea from '@/components/Base/BaseTextarea.vue'
import BaseRadio from '@/components/Base/BaseRadio.vue'
import useVuelidate from '@vuelidate/core'
import { required, helpers, requiredIf } from '@vuelidate/validators'
import { cancelSubscription } from '@/api/subscriptions'

export default {
  name: 'SubscriptionCancelCanceling',
  components: { BaseRadio, BaseTextarea, BaseButton },
  props: {
    subscription: {
      type: Object,
      default: () => ({})
    },
    subscriptionType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      v$: useVuelidate(),
      cancelOptions: [
        {
          value: 'I am switching to another donating platform.',
          label: 'I am switching to another donating platform.'
        },
        {
          value: 'I am switching to a different subscription plan on CUDDLY.',
          label: 'I am switching to a different subscription plan on CUDDLY.'
        },
        {
          value: 'I prefer to donate to the animals I choose.',
          label: 'I prefer to donate to the animals I choose.'
        },
        {
          value: 'I had issues with payment and billing.',
          label: 'I had issues with payment and billing.'
        },
        {
          value: "I don't want to donate anymore.",
          label: "I don't want to donate anymore."
        },
        { value: 'other', label: 'Other (please specify below).' }
      ],
      cancelReason: '',
      cancelOption: ''
    }
  },
  methods: {
    async handleCancelSubscription() {
      this.v$.cancelOption.$touch()
      this.v$.cancelReason.$touch()

      if (!this.v$.cancelOption.$invalid && !this.v$.cancelReason.$invalid) {
        this.loading = true
        const subscriptionId = this.subscription.subscription_id
        const { status } = await cancelSubscription({
          subscription_id: subscriptionId,
          reason: this.cancelOption,
          details: this.cancelReason || '',
          method: this.subscription.method
        })
        this.loading = true

        if (status >= 400) {
          const error =
            status === 422
              ? 'Subscription cannot be paused since it is already cancelled.'
              : 'Unfortunately we have experienced an error. Please try again later.'
          this.$toast.error('Whoops, looks like something went wrong.')
          this.emitter.emit('subscription-cancel_failed', { error })
        } else {
          this.emitter.emit('subscription-cancel_canceled', {
            canceled: true
          })
        }

        this.loading = false
      }
    }
  },
  validations: {
    cancelOption: {
      required: helpers.withMessage('Please select a reason.', required)
    },
    cancelReason: {
      required: helpers.withMessage(
        'Please specify a reason.',
        requiredIf(function (value, vm) {
          return vm.cancelOption === 'other'
        })
      )
    }
  }
}
</script>

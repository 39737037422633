<template>
  <div
    v-show="!isSubscriptionDetailsOpen"
    class="absolute top-16 flex flex-col md:flex-row flex-grow md:h-[98px] items-center w-full bg-white p-5 md:px-16 lg:px-[120px]"
  >
    <div class="flex flex-1 w-full items-center h-[50px] md:h-auto">
      <img
        class="h-[50px] w-[50px] rounded-full"
        :src="logo"
        :alt="name"
        v-if="logo"
      />
      <BaseIcon name="profile" class="h-[50px] w-[50px]" v-else />
      <div class="flex flex-col ml-3 w-full">
        <h5>{{ name }}</h5>
        <div class="flex md:gap-[14px]">
          <div class="flex gap-2" v-if="phone">
            <BaseIcon
              name="phone"
              class="h-5 w-5 text-gray-500"
              title="Phone"
              fill="currentColor"
              stroke-width="0"
            />
            <span class="body-text-3 w-[120px] text-ellipsis overflow-hidden">
              {{ formattedPhone }}
            </span>
          </div>
          <div class="flex gap-2">
            <BaseIcon
              name="mail"
              class="h-5 w-5 text-gray-500"
              fill="currentColor"
            />
            <span
              class="body-text-3 w-[120px] md:w-auto text-ellipsis overflow-hidden"
            >
              {{ email }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import { profileUpdated } from '@/mixins/profileUpdated'

export default {
  name: 'DashboardBasicProfile',
  components: {
    BaseIcon
  },
  mixins: [profileUpdated],
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      logo: userInfo?.logo,
      phone: userInfo?.phone,
      name: userInfo?.name ? userInfo.name : 'Donor',
      email: userInfo?.email,
      isSubscriptionDetailsOpen: false,
      isSubscriptionReactivateOpen: false
    }
  },
  watch: {
    '$store.state.isSubscriptionDetailsOpen': function (value) {
      this.isSubscriptionDetailsOpen = value
    },
    '$store.state.isSubscriptionReactivateOpen': function (value) {
      console.log('isSubscriptionReactivateOpen', value)
      this.isSubscriptionReactivateOpen = value
    }
  },
  computed: {
    formattedPhone() {
      return this.phone.replace(/.*\(/, '(')
    }
  }
}
</script>

<template>
  <div
    class="flex flex-col p-5 md:pb-0 mt-5 w-full min-h-screen md:min-h-[693px]"
  >
    <h4 class="text-brand-navy font-extrabold -ml-0.5">Payment Method</h4>
    <div class="flex-1 flex-grow">
      <div
        class="flex flex-1 flex-grow min-h-[452px] md:min-h-[524px] justify-center items-center w-full"
        v-if="loading"
      >
        <BaseLoader />
      </div>

      <div class="my-5 flex flex-col gap-5" v-else>
        <SubscriptionPaymentCard
          v-for="(card, index) in cards"
          :key="card.id"
          :card="card"
          :index="index"
        />
      </div>
    </div>
    <div class="flex flex-0 flex-col w-full items-center mb-28 md:mb-10">
      <BaseButton
        id="add-card"
        class="py-[14px] tracking-1"
        @click="handleAddCard"
        :disabled="loading"
        variant="outlined"
      >
        Add a Payment Method
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import SubscriptionPaymentCard from '@/components/Subscription/SubscriptionPayment/SubscriptionPaymentCard.vue'
import BaseLoader from '@/components/Base/BaseLoader.vue'
import { getCards } from '@/api/cards'
export default {
  name: 'SubscriptionPaymentDefault',
  components: { BaseLoader, SubscriptionPaymentCard, BaseButton },
  props: {
    subscriptionId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      cards: []
    }
  },
  created() {
    this.getCards()
  },
  methods: {
    async getCards() {
      this.loading = true
      try {
        const { status, data } = await getCards(this.subscriptionId)

        if (status !== 200) {
          this.$toast.error('Whoops, looks like something went wrong.')

          return
        }

        this.cards = data?.data || []
      } catch (error) {
        console.error(error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    },
    handleAddCard() {
      this.emitter.emit('add-card')
    }
  }
}
</script>

import { controller, refreshController } from '@/config/api'

const abortController = {
  beforeRouteLeave(to, from, next) {
    controller.abort()
    refreshController()
    next()
  }
}

export { abortController }

<template>
  <div class="w-full h-auto mt-3 mb-6 max-w-max px-5 md:px-0">
    <div class="flex w-full bg-white p-4 gap-2 rounded-lg shadow-md">
      <div>
        <BaseIcon name="dollar-colored" />
      </div>
      <div class="flex flex-col">
        <span
          class="font-bold leading-6 text-brand-navy text-base tracking-0.2"
        >
          Dashboard Update Coming Soon
        </span>
        <span class="tracking-0.2 text-sm leading-21 text-brand-navy">
          When you donate an amount towards a wish list, this amount will be
          reflected in your dashboard.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'

export default {
  name: 'DashboardUpdate',
  components: { BaseIcon }
}
</script>

<template>
  <div class="flex flex-col" :class="{ 'pb-6': error }">
    <label
      class="text-gray-900 text-sm font-medium leading-21"
      :class="{ 'text-red-500': error }"
    >
      <slot />
    </label>

    <fieldset>
      <div
        class="flex mt-2 h-[42px] gap-5 justify-start"
        :class="{
          'animate-pulse': loading,
          'flex-col gap-3': orientation !== 'horizontal'
        }"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="flex items-center gap-2"
        >
          <input
            type="radio"
            :id="getRadioId(option.label)"
            :name="id"
            :class="radioClasses"
            :value="option.value"
            :checked="option.value === modelValue"
            :disabled="disabled"
            v-bind="$attrs"
            @click="this.$emit('update:modelValue', option.value)"
            :title="option.label"
          />
          <label
            :for="getRadioId(option.label)"
            class="text-gray-900 text-sm font-medium leading-21 cursor-pointer"
          >
            {{ option.label }}
          </label>
        </div>
        <span
          role="alert"
          class="block text-sm text-red-600 font-normal leading-5"
          v-if="error"
        >
          {{ error }}
        </span>
      </div>
    </fieldset>
  </div>
</template>

<script>
const RADIO_CLASSES =
  'h-4 w-4 text-xs border border-gray-300 bg-gray-50 cursor-pointer checked:bg-none ring-red-200 focus:ring-red-200 focus:ring-offset-0 checked:ring-red-200 checked:ring-offset-0'
const RADIO_VARIANTS = {
  primary: `${RADIO_CLASSES} text-brand-navy`,
  secondary: `${RADIO_CLASSES} text-coral-500`
}

export default {
  name: 'BaseRadio',
  props: {
    id: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'primary'
    },
    error: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [Boolean, String, Number],
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    options: {
      type: Array,
      default: () => []
    },
    orientation: {
      type: String,
      default: 'horizontal'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    if (this.id === undefined) {
      throw new Error('BaseRadio requires an id')
    }
  },
  computed: {
    radioClasses() {
      return RADIO_VARIANTS[this.variant.toLowerCase()]
    },
    radioOrientation() {
      return this.orientation === 'horizontal' ? '' : 'flex-col gap-3'
    }
  },
  methods: {
    getRadioId(label) {
      return `${this.id}-${label.toLowerCase().replace(' ', '-')}`
    }
  },
  watch: {
    modelValue(value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

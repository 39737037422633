import { destroy, get, post, failedRequest, controller } from '@/config/api'

const getDashboardMetrics = async () => {
  try {
    const { status, data } = await get('/api/donor', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getDashboardBarMetrics = async () => {
  try {
    const { status, data } = await get('/api/donor/bar-metrics', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getDonorFeed = async () => {
  try {
    const { status, data } = await get('/api/donor/feed', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getCampaignsDonated = async (params) => {
  try {
    const { status, data } = await get('/api/donor/campaigns-donated', {
      params,
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getProfile = async () => {
  try {
    const { status, data } = await get('/api/donor/profile', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getBreeds = async () => {
  try {
    const { status, data } = await get('/api/donor/breeds', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getShelters = async () => {
  try {
    const { status, data } = await get('/api/donor/shelters', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const postProfile = async (payload) => {
  try {
    const { status, data } = await post('/api/donor/profile', payload)

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const postPassword = async (payload) => {
  try {
    const { status, data } = await post('/api/donor/profile/password', payload)

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const deletePaymentMethod = async (cardId) => {
  try {
    const { status, data } = await destroy(
      `/api/donor/profile/payment-method/${cardId}`
    )

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const postPaymentMethod = async (payload) => {
  try {
    const { status, data } = await post(
      '/api/donor/profile/payment-method',
      payload
    )

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

export {
  getDashboardMetrics,
  getDashboardBarMetrics,
  getDonorFeed,
  getCampaignsDonated,
  getProfile,
  getBreeds,
  getShelters,
  postProfile,
  postPassword,
  deletePaymentMethod,
  postPaymentMethod
}

<template>
  <svg
    viewBox="0 0 32 32"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="0"
    v-bind="$attrs"
  >
    <path
      d="M15.413,16.003l8.193-8.285c0.391-0.395,0.391-1.034,0-1.429c-0.391-0.394-1.024-0.394-1.414,0l-8.9,8.999   c-0.385,0.388-0.378,1.046,0,1.428l8.9,8.999c0.391,0.394,1.024,0.394,1.414,0s0.391-1.034,0-1.428L15.413,16.003z"
      fill="currentColor"
    />
    <path
      d="M9,5.988c-0.552,0-1,0.448-1,1v18c0,0.552,0.448,1,1,1s1-0.448,1-1v-18C10,6.435,9.552,5.988,9,5.988z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronFirst'
}
</script>

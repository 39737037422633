const NETWORKS = {
  facebook: 'https://www.facebook.com/welovecuddly',
  twitter: 'https://twitter.com/welovecuddly',
  instagram: 'https://www.instagram.com/welovecuddly',
  youtube: 'https://www.youtube.com/channel/UCUAGvVfcMcQ2m_mK6ivzytg',
  tiktok: 'https://www.tiktok.com/@welovecuddly'
}

export default {
  methods: {
    openSocialNetwork: function (network) {
      window.open(NETWORKS[network], '_blank', 'noreferrer')
    }
  }
}

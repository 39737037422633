<template>
  <div
    class="w-full h-[56px] flex bg-white items-center justify-between shadow-1 p-2.5 rounded-lg"
  >
    <div class="min-w-[156px] flex items-center">
      <img :src="brandIcon" :alt="brand" v-if="brandIcon" />
      <BaseIcon name="credit-card" class="h-7 w-7 text-gray-500" v-else />
      <span class="body-text-1 text-gray-700 ml-2.5 capitalize">
        {{ brand }} {{ cardNumber }}
      </span>
    </div>
    <div class="w-[54px] body-text-1 text-gray-700" v-if="isDefault">
      <span class="body-text-1 text-gray-700">Default</span>
    </div>
    <div class="w-[29px]">
      <span
        :id="editId"
        class="body-text-1 text-brand-navy underline underline-offset-4 cursor-pointer"
        @click="handleEdit"
      >
        Edit
      </span>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'

export default {
  name: 'SubscriptionPaymentCard',
  components: { BaseIcon },
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({
        brand: '',
        last4: '',
        is_default: false
      })
    },
    index: {
      type: Number,
      required: true
    }
  },
  mounted() {},
  methods: {
    handleEdit() {
      this.emitter.emit('edit-card', { card: this.card })
    }
  },
  computed: {
    brand() {
      let name = this.card.brand

      if (name === 'American Express') {
        name = 'Amex'
      }

      return name
    },
    brandIcon() {
      let icon = this.brand.toLowerCase()

      if (icon === 'american express') {
        icon = 'amex'
      }

      const availableIcons = [
        'amex',
        'discover',
        // 'jcb',
        // 'mastercard',
        // 'unionpay',
        'visa'
      ]

      if (!availableIcons.includes(icon)) {
        return ''
      }

      return require(`@/assets/${icon}.png`)
    },
    cardNumber() {
      return `*${this.card.last4}`
    },
    isDefault() {
      return Boolean(this.card?.is_default) || false
    },
    editId() {
      return `edit-card-${this.index}`
    }
  }
}
</script>

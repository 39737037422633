import { get, post, destroy, failedRequest, controller } from '@/config/api'

const getCards = async (subscriptionId) => {
  try {
    const { status, data } = await get(
      `/api/donor/subscription/${subscriptionId}/cards`,
      {
        signal: controller.signal
      }
    )

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const getClientSecret = async (payload) => {
  try {
    const { status, data, errors } = await post(
      '/api/donor/stripe/client-secret',
      payload
    )

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

const postCards = async (payload) => {
  try {
    const { status, data, errors } = await post(
      '/api/donor/subscription/cards',
      payload
    )

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

const postSetCardAsDefault = async (payload) => {
  try {
    const { status, data } = await post(
      '/api/donor/customer/set-default-card',
      payload
    )

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const deleteCards = async (subscriptionId, payload) => {
  try {
    const { status, data } = await destroy(
      `/api/donor/subscriptions/${subscriptionId}/payment-method`,
      {
        data: {
          ...payload
        }
      }
    )

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

export { getCards, postCards, postSetCardAsDefault, deleteCards, getClientSecret }

<template>
  <div
    class="h-screen md:h-full md:w-full absolute top-0 left-0 right-0 md:top-4 md:bg-black md:bg-opacity-20"
  >
    <div
      class="min-h-full absolute md:relative left-0 right-0 top-0 md:top-0 md:m-auto animate-fade-up"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseBottomSheet'
}
</script>

<template>
  <div class="w-full mt-10">
    <h4 class="text-brand-navy px-5 md:px-0">Welcome back, {{ donorName }}!</h4>
  </div>
</template>

<script>
export default {
  name: 'DashboardWelcomeBack',
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      donorName:
        userInfo.name && userInfo.name.length > 1 ? userInfo.name : 'Donor'
    }
  }
}
</script>

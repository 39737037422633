<template>
  <header
    class="block relative z-10"
    :class="{
      'h-16 md:h-16': isSubscriptionDetailsOpen,
      'h-36 md:h-[164px]': !isSubscriptionDetailsOpen
    }"
  >
    <DashboardLoadingBar />
    <DashboardNavBar />
    <DashboardBasicProfile />
  </header>
  <main class="mx-0 md:mx-16 lg:mx-[120px] antialiased">
    <slot />
  </main>
</template>

<script>
import DashboardLoadingBar from '@/components/Dashboard/DashboardLoadingBar.vue'
import DashboardNavBar from '@/components/Dashboard/DashboardNavBar.vue'
import DashboardBasicProfile from '@/components/Dashboard/DashboardBasicProfile.vue'

export default {
  name: 'DashboardLayout',
  components: {
    DashboardLoadingBar,
    DashboardNavBar,
    DashboardBasicProfile
  },
  data() {
    return {
      isSubscriptionDetailsOpen: false
    }
  },
  watch: {
    '$store.state.isSubscriptionDetailsOpen': function (value) {
      this.isSubscriptionDetailsOpen = value
    }
  }
}
</script>

<template>
  <button
    type="button"
    :id="id"
    :name="id"
    :data-testid="id"
    :class="buttonClasses"
    :disabled="disabled"
    @click.prevent="onClick"
    v-bind="$attrs"
  >
    <BaseIcon
      name="spin"
      class="animate-spin mr-1 h-4 w-4"
      :class="{
        hidden: !loading
      }"
    />
    <slot />
    <span
      :class="{
        'absolute left-0 border-b border-brand-navy h-[1px] bottom-1 w-[calc(100%_-_4px)]':
          variant === 'underline'
      }"
    ></span>
  </button>
</template>

<script>
import BaseIcon from './BaseIcon.vue'

const BUTTON_CLASSES =
  'relative w-full flex flex-row justify-center items-center text-sm font-bold tracking-[5px] leading-14 py-2 rounded-[5px] cursor-pointer border outline-none focus-visible:outline-none focus:outline-none transition duration-150 ease-in-out hover:shadow-3 disabled:cursor-default disabled:shadow-none disabled:text-black'
const BUTTON_VARIANTS = {
  primary: `${BUTTON_CLASSES} bg-brand-navy border-brand-navy text-white active:bg-black active:border-black disabled:bg-navy-deactivated disabled:border-navy-deactivated disabled:text-black`,
  secondary: `${BUTTON_CLASSES} bg-coral-500 border-coral-500 text-black active:bg-brand-navy active:border-brand-navy active:text-white disabled:bg-coral-deactivated disabled:border-coral-deactivated disabled:text-brand-navy`,
  outlined: `${BUTTON_CLASSES} bg-white text-brand-navy border-secondary-steel active:border-black active:text-black disabled:border-secondary-light-blue disabled:text-secondary-steel`,
  text: `${BUTTON_CLASSES} bg-transparent border-none outline-none text-brand-navy hover:text-brand-pink active:text-black disabled:text-secondary-steel focus:outline-none hover:shadow-none`,
  underline: `${BUTTON_CLASSES} relative bg-transparent border-none outline-none text-brand-navy hover:text-brand-pink active:text-black disabled:text-secondary-steel focus:outline-none hover:shadow-none`
}

export default {
  name: 'BaseButton',
  components: { BaseIcon },
  props: {
    id: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    class: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    if (this.id === undefined) {
      throw new Error('BaseButton requires an id')
    }
  },
  computed: {
    buttonClasses() {
      let base = BUTTON_VARIANTS[this.variant.toLowerCase()]
      base = `${base} ${this.height} ${this.width} ${this.class}`.trim()

      if (this.loading) {
        base = `${base} min-w-max`
      }

      return base
    }
  }
}
</script>

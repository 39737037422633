const CUDDLY_URL = `${process.env.VUE_APP_CUDDLY_URL}`

export default {
  methods: {
    getCampaignUrl: function (campaign) {
      let url = CUDDLY_URL

      if (campaign?.type === 'core') {
        url = `${url}/donate/${campaign?.id}`
      } else if (campaign?.type === 'pantry') {
        url = `${url}/pantry/${campaign?.id}`
      }

      return url
    },
    getSuccessStoryUrl: function (story) {
      return `${CUDDLY_URL}/success-story/${story?.page_id}/${story?.slug}`
    }
  }
}

<template>
  <div class="w-full mb-10 px-5 md:px-0" v-if="subscriptions?.length > 0">
    <div class="flex-1 w-full md:min-w-max mb-5">
      <h4 class="text-brand-navy">My Subscriptions</h4>
    </div>
    <div class="flex flex-col xl:flex-row gap-5 flex-1 w-full justify-between">
      <div
        class="flex w-full h-20 bg-white shadow-1 rounded-lg justify-center items-center"
        v-if="loading"
      >
        <BaseLoader :loading="loading" />
      </div>
      <div class="flex flex-col flex-1 space-y-[30px]" v-else>
        <div
          v-if="subscriptions?.length < 1"
          class="flex flex-col w-full h-auto bg-white shadow-1 rounded-lg py-[30px] px-[26px]"
        >
          <div class="flex w-full justify-center mb-2.5">
            <span class="body-text-1 text-brand-navy">
              Don't Miss Our Do-Good Subscriptions!
            </span>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div
              class="flex flex-col w-full h-auto bg-white shadow-md rounded-lg p-6"
            >
              <div
                class="flex items-start justify-center w-full h-48 md:h-36 lg:h-auto"
              >
                <img
                  alt="CUDDLY Club"
                  src="@/assets/cuddly-club-subscriptions-state.png"
                  class="w-[305px] md:w-[290px] lg:w-full h-[176.95px] md:h-auto lg:h-auto"
                />
              </div>
              <div class="mt-[26px] mb-[18px]">
                <span
                  class="w-[203px] h-[25px] rounded-md px-2.5 py-0.5 bg-secondary-sand body-text-2 text-brand-navy"
                >
                  Monthly Giving Subscription
                </span>
              </div>
              <h5>CUDDLY CLUB</h5>
              <ul
                class="list-disc body-text-1 text-gray-500 mt-[10px] mb-4 px-6 h-[216px]"
              >
                <li>
                  Donations are pooled together to make a more significant
                  impact to help save as many animals as possible.
                </li>
                <li>
                  CUDDLY sends the donated supplies directly to the animal
                  shelters & rescues on your behalf.
                </li>
                <li>
                  CUDDLY Club members receive newsletter updates on the animals
                  they have helped.
                </li>
              </ul>
              <div class="flex w-full justify-center mb-4">
                <a
                  class="custom-underline"
                  target="_blank"
                  rel="noreferrer"
                  :href="cuddlyClubUrl"
                >
                  Learn More
                </a>
              </div>
              <div class="w-full">
                <BaseButton
                  id="join-cuddly-club"
                  class="w-full uppercase h-[49.5px]"
                  @click="goToCuddlyClubCheckout"
                >
                  Join CUDDLY Club
                </BaseButton>
              </div>
            </div>
            <div
              class="flex flex-col w-full h-auto bg-white shadow-md rounded-lg p-6"
            >
              <div
                class="flex relative items-start justify-center w-full h-48 md:h-36 lg:h-auto"
              >
                <img
                  alt="CUDDLY Club"
                  src="@/assets/full-bowls-club-subscriptions-state.png"
                  class="w-[305px] md:w-[290px] lg:w-full h-[176.95px] md:h-auto lg:h-auto"
                />
              </div>
              <div class="mt-[26px] mb-[18px]">
                <span
                  class="w-[203px] h-[25px] rounded-md px-2.5 py-0.5 bg-secondary-sand body-text-2 text-brand-navy"
                >
                  Monthly Giving Subscription
                </span>
              </div>
              <h5>FULL BOWLS CLUB</h5>
              <ul
                class="list-disc body-text-1 text-gray-500 mt-[10px] mb-4 px-6 h-[216px]"
              >
                <li>Support a specific organization each month.</li>
                <li>
                  The funds provide precisely what they need from the Wish List
                  items for pets and families in need.
                </li>
                <li>
                  Help multiple animals within the organization of choice.
                </li>
              </ul>
              <div class="flex w-full justify-center mb-4">
                <a
                  class="custom-underline"
                  target="_blank"
                  rel="noreferrer"
                  :href="fullBowlsUrl"
                >
                  Learn More
                </a>
              </div>
              <div class="w-full">
                <BaseButton
                  id="join-full-bowls-club"
                  class="w-full uppercase h-[49.5px]"
                  @click="goToFullBowlsCheckout"
                >
                  Join Full Bowls Club
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
        <template v-else>
          <div
            class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 xl:gap-6"
            :class="{
              'lg:grid-cols-3': subscriptions.length < 4,
              'lg:grid-cols-4': subscriptions.length > 4,
              'xl:grid-cols-3': subscriptions.length < 4,
              'xl:grid-cols-4': subscriptions.length > 4
            }"
          >
            <SubscriptionCard
              v-for="subscription in subscriptions"
              :key="subscription.id"
              :subscription="subscription"
              :onEdit="onEdit"
              :onReactivate="onReactivate"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionCard from '@/components/Subscription/SubscriptionCard.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseLoader from '@/components/Base/BaseLoader.vue'
import { getSubscriptions } from '@/api/subscriptions'

export default {
  name: 'DashboardSubscriptions',
  components: {
    SubscriptionCard,
    BaseButton,
    BaseLoader
  },
  props: {
    onEdit: {
      type: Function,
      required: true
    },
    onReactivate: {
      type: Function,
      required: true
    }
  },
  emits: ['hasSubscription'],
  data() {
    return {
      subscriptions: [],
      loading: false,
      cuddlyClubUrl: `${process.env.VUE_APP_CUDDLY_URL}/club`,
      fullBowlsUrl: `${process.env.VUE_APP_CUDDLY_URL}/fullbowls`,
      subscriptionsChanged: false
    }
  },
  methods: {
    goToCuddlyClubCheckout() {
      window.open(
        `${process.env.VUE_APP_CUDDLY_URL}/club`,
        '_blank',
        'noreferrer'
      )
    },
    goToFullBowlsCheckout() {
      window.open(
        `${process.env.VUE_APP_CUDDLY_URL}/fullbowls`,
        '_blank',
        'noreferrer'
      )
    },
    async fetchSubscriptions() {
      this.loading = true
      const { data } = await getSubscriptions()
      this.subscriptions = data

      // wishpack == cuddly club
      // fullbowls == full bowls club
      const wishpackSub = data?.find((value) => {
        return value.type === 'wishpack'
      })
      const hasFullBowlsSub =
        data?.length > 0 && typeof wishpackSub !== 'undefined'

      if (hasFullBowlsSub) {
        this.$emit('hasSubscription', true)
      }

      this.loading = false
    }
  },
  beforeMount() {
    this.fetchSubscriptions()
  },
  created() {
    this.emitter.on('subscriptions-refresh', ({ reload }) => {
      if (reload) {
        this.fetchSubscriptions()
      }
    })
  }
}
</script>

<style scoped>
.custom-underline {
  position: relative;
  display: block;
  text-transform: uppercase;
  letter-spacing: 5px;
  width: max-content;
  color: #2a415b;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
}
.custom-underline:hover {
  color: #ff696e;
}
.custom-underline:after {
  content: '';
  border-bottom: 1px solid #2a415b;
  padding-bottom: 1px;
  display: block;
  position: absolute;
  right: 5px;
  left: 0;
}
.custom-underline:hover::after {
  border-bottom: 1px solid #ff696e;
  color: #2a415b;
}
</style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <rect width="24" height="24" rx="12" :fill="fillColor" />
    <path
      d="M13.219 10.781C12.1776 9.73965 10.4891 9.73965 9.44772 10.781L6.78105 13.4477C5.73965 14.4891 5.73965 16.1776 6.78105 17.219C7.82245 18.2603 9.51089 18.2603 10.5523 17.219L11.2867 16.4846M10.781 13.219C11.8224 14.2603 13.5109 14.2604 14.5523 13.219L17.219 10.5523C18.2603 9.51089 18.2603 7.82245 17.219 6.78105C16.1776 5.73965 14.4891 5.73965 13.4477 6.78105L12.7146 7.51412"
      :stroke="logoColor"
      stroke-width="1.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconLink',
  props: {
    logoColor: {
      type: String,
      default: '#2A415B'
    },
    fillColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

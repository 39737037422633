<template>
  <div
    class="relative min-h-screen md:min-h-[693px] w-full md:max-w-[390px] bg-white flex flex-col lg:flex-row top-16 md:shadow-2 md:rounded-2xl md:mx-auto overflow-hidden"
  >
    <div class="flex flex-col w-full h-full items-start">
      <div class="absolute top-0 md:right-0 p-5 pl-[18px] pt-2.5 md:pr-5">
        <BaseIcon
          name="arrow-left"
          class="md:hidden h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
          v-show="!updated || failed"
        />
        <BaseIcon
          name="close"
          class="hidden md:block h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
        />
      </div>
      <SubscriptionEditUpdated
        v-if="updated"
        :subscription="subscription"
        :subscription-type="subscriptionType"
        :is-full-bowls="isFullBowls"
        :image-src="imageSrc"
      />
      <SubscriptionEditFailed
        v-else-if="failed"
        :error="error"
        :subscription="subscription"
      />
      <SubscriptionEditDefault
        v-else
        :subscription-type="subscriptionType"
        :subscription="subscription"
      />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import SubscriptionEditDefault from '@/components/Subscription/SubscriptionEdit/SubscriptionEditDefault.vue'
import SubscriptionEditFailed from '@/components/Subscription/SubscriptionEdit/SubscriptionEditFailed.vue'
import SubscriptionEditUpdated from '@/components/Subscription/SubscriptionEdit/SubscriptionEditUpdated.vue'
import scrollTop from '@/mixins/scrollTop'

export default {
  name: 'SubscriptionEdit',
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  mixins: [scrollTop],
  components: {
    SubscriptionEditFailed,
    SubscriptionEditDefault,
    SubscriptionEditUpdated,
    BaseIcon
  },
  data() {
    return {
      updated: false,
      failed: false,
      error: 'Something went wrong. Please try again.'
    }
  },
  created() {
    this.scrollTop()
    this.emitter.on('subscription-edit_failed', ({ error }) => {
      this.failed = true
      this.error = error
      this.scrollTop()
    })
    this.emitter.on('subscription-edit_updated', () => {
      this.updated = true
      this.failed = false
      this.error = ''
      this.scrollTop()
    })
  },
  methods: {
    handleBack() {
      this.emitter.emit('back-to-subscription', { reload: this.updated })
    }
  },
  computed: {
    subscriptionType() {
      return this.subscription.type === 'fullbowls'
        ? 'Full Bowls Club'
        : 'CUDDLY Club'
    },
    isFullBowls() {
      return this.subscription.type === 'fullbowls'
    },
    imageSrc() {
      const images = require.context('@/assets', true)
      const image =
        this.subscription.type === 'fullbowls'
          ? 'full-bowls-club-subscriptions-state.png'
          : 'cuddly-club-mutt-scouts.png'

      return images(`./${image}`)
    }
  }
}
</script>

<template>
  <div
    class="w-full md:min-w-[198px] lg:min-w-[246px] h-[111px] md:min-h-[214px] bg-white rounded-lg p-[10px] md:pt-6 md:pb-5 shadow-2 relative"
  >
    <div class="flex flex-col items-center">
      <BaseIcon
        :name="icon"
        :class="customClass"
        :title="title"
        aria-hidden="true"
      />
      <div class="flex">
        <BaseLoader
          v-if="isLoading"
          :loading="isLoading"
          class="mt-3 md:mt-5"
        />
        <template v-else>
          <h3 class="hidden md:block text-brand-navy">
            {{ asCurrency ? formatCurrency(value) : value }}
          </h3>
          <h4
            class="md:hidden text-brand-navy"
            :class="{
              'text-[4.5vw]': getTextLength >= 8 && getTextLength <= 10,
              'text-[3.5vw]': getTextLength >= 11 && getTextLength <= 13
            }"
          >
            {{ asCurrency ? formatCurrency(value) : value }}
          </h4>
        </template>
      </div>
    </div>
    <div class="flex w-full justify-center text-center">
      <span class="hidden md:block body-text-1 text-gray-700">{{ title }}</span>
      <span
        class="md:hidden detail-3 text-gray-700 leading-[14.4px] px-6 md:px-0"
      >
        {{ title }}
      </span>
    </div>
    <div
      class="hidden md:flex flex-col items-center mt-[15px] px-5"
      v-if="!isLoading"
    >
      <div class="flex w-full h-2 bg-gray-200" v-if="metrics.length > 0">
        <div
          class="h-full cursor-pointer"
          v-for="({ title, bgClass, percentage }, index) in metrics"
          :key="index"
          :class="bgClass"
          :style="getStyle(percentage)"
        >
          <div
            class="h-full w-full relative flex justify-center"
            :class="bgClass"
            @mouseover="setIndex(index)"
            @mouseleave="setIndex(-1)"
          >
            <BaseTooltip :show="isHovered(index)">
              <div class="flex flex-col mt-1 detail-3 text-gray-500">
                <span>{{ title }}</span>
                <span class="-mt-1">
                  {{ getPercentageRound(percentage) }}%
                </span>
              </div>
            </BaseTooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import BaseLoader from '@/components/Base/BaseLoader.vue'
import currency from '@/mixins/currency'
import BaseTooltip from '@/components/Base/BaseTooltip.vue'

export default {
  name: 'DashboardMetricCard',
  components: {
    BaseIcon,
    BaseLoader,
    BaseTooltip
  },
  mixins: [currency],
  props: {
    icon: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#fc5e71'
    },
    size: {
      type: String,
      default: '10px'
    },
    metrics: {
      type: Array,
      default: () => []
    },
    asCurrency: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoading() {
      return Boolean(this.loading)
    },
    getTextLength() {
      const value = this.asCurrency
        ? this.formatCurrency(this.value)
        : this.value

      return value.length
    }
  },
  data() {
    return {
      hovered: false,
      index: -1
    }
  },
  methods: {
    getStyle(percentage) {
      return `width: ${percentage}%`
    },
    getPercentageRound(percentage) {
      return Math.round(percentage)
    },
    setIndex(index) {
      this.index = index
    },
    isHovered(index) {
      return this.index === index
    }
  }
}
</script>

import {failedRequest, post, get} from '@/config/api'

const isDonorAuthenticated = async () => {
  try {
    const { status, data } = await get('/api/donor/authenticated')

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const donorLogin = async (email, password) => {
  const payload = {
    email,
    password,
    resourceName: 'donorResource'
  }

  try {
    return await post('/api/auth/login', payload)
  } catch (e) {
    return e
  }
}

const donorExternalLogin = async (loginString) => {
  const payload = {
    login_string: loginString
  }

  try {
    return await post('/api/external-login', payload)
  } catch (e) {
    return e
  }
}

const sendResetLink = async (email) => {
  const payload = {
    email
  }

  try {
    const { status, data } = await post('/api/password-link', payload)

    return { status, data }
  } catch (e) {
    return e
  }
}

const resetDonorsPassword = async (
  token,
  password,
  passwordConfirmation,
  emailHash
) => {
  const payload = {
    token,
    password,
    password_confirmation: passwordConfirmation,
    emailHash
  }

  try {
    const { status, data } = await post('/api/donor/password-reset', payload)

    return { status, data }
  } catch (e) {
    const data = e.response.data
    const status = e.response.status

    return { data, status }
  }
}

const donorLogout = async (token) => {
  try {
    return await post('/api/auth/logout', { token })
  } catch (e) {
    return null
  }
}

export {
  donorLogin,
  donorLogout,
  donorExternalLogin,
  sendResetLink,
  resetDonorsPassword,
  isDonorAuthenticated
}

<template>
  <div
    class="flex w-full h-[172px] md:h-[212px] justify-center items-center bg-secondary-light-blue"
  >
    <div v-if="isFullBowls" class="text-brand-navy text-right">
      <h2 class="font-bold">Full Bowls</h2>
      <h2 class="font-extrabold">Club</h2>
    </div>
    <img
      :alt="subscriptionType"
      src="@/assets/cuddly-club.png"
      class="max-w-[234.51px] max-h-[130px] h-full"
      v-else
    />
  </div>
  <div class="flex flex-col p-5">
    <h4 class="text-brand-navy font-extrabold text-[30px] leading-45 mb-2.5">
      Thank you.
    </h4>
    <span class="body-text-1 mb-10 text-gray-700">
      You have successfully unsubscribed from {{ subscriptionType }}. Your membership will end today.
    </span>
    <span class="subtitle-1 text-brand-navy">
      Did you cancel your subscription by accident?
    </span>
    <BaseButton
      id="back-to-dashboard"
      @click="handleJoinClub"
      variant="underline"
      class="mt-2.5 uppercase max-w-max hover:text-brand-navy"
    >
      JOIN AGAIN
    </BaseButton>
    <div
      class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
    >
      <BaseButton
        id="back-to-dashboard"
        @click="handleBack"
        class="py-[14px] px-[66px] md:px-[33px] lg:px-[66px] w-full tracking-2"
      >
        Done
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import date from '@/mixins/date'
import { Segment } from '@/services/segment'

export default {
  name: 'SubscriptionCancelCanceled',
  mixins: [date],
  components: { BaseButton },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    subscriptionType: {
      type: String,
      required: true
    },
    isFullBowls: {
      type: Boolean,
      required: true
    },
    clubUrl: {
      type: String,
      required: true
    }
  },
  mounted() {
    // Segment event tracking for Subscription Cancellations
    const subscriptionId = this.subscription.subscription_id;
    if (this.$segment && subscriptionId) {
      this.$segment.track('Subscription Cancellation Completed', {
        subscriptionId: subscriptionId,
        dateTime: new Date().toISOString(),
        platform: 'Donor Dashboard',
      });
    } else {
      console.warn('Segment tracking not executed');
    }
  },
  methods: {
    handleBack() {
      this.emitter.emit('subscriptions-refresh', { reload: true })
    },
    handleJoinClub() {
      window.open(this.clubUrl, '_blank', 'noreferrer')
    }
  },
  computed: {
    cancelDate() {
      const endDate = this.setDate(this.subscription.subscription_start, {
        unit: 'month',
        amount: 1
      })

      return this.formatDate(endDate, 'MMM DD, YYYY')
    }
  }
}
</script>

<template>
  <span class="body-text-1 mt-2.5 text-gray-700">
    Select or type in your desired monthly donation below.
  </span>
  <div class="grid grid-cols-3 gap-[13.46px] mt-2.5">
    <template v-for="option in options" :key="option.id">
      <div class="flex flex-col justify-end text-center">
        <div
          class="flex justify-center items-center py-2.5 px-[15px] w-[108px] h-[47px] rounded-[5px] cursor-pointer"
          :class="{
            'bg-brand-navy text-white': option.value === selectedAmount,
            'bg-[#F2F2F2] text-brand-navy': option.value !== selectedAmount
          }"
          @click="handleAmountClick(option.value)"
        >
          <h6>${{ option.value }}</h6>
        </div>
      </div>
    </template>
    <div class="flex flex-col justify-end text-center">
      <input
        id="monthly-other"
        name="subscription"
        type="text"
        placeholder="Custom"
        class="rounded-[5px] placeholder-brand-navy w-[108px] h-[47px] text-center font-bold text-lg leading-27 tracking-0.2 focus:ring-black focus:outline-none border-0"
        :class="{
          'bg-brand-navy text-white': customAmount || isCustomAmountFocused,
          'bg-[#F2F2F2] text-brand-navy':
            !customAmount && !isCustomAmountFocused
        }"
        v-model="customAmount"
        v-numeric-only:active="true"
        maxlength="6"
        v-mask="'$####'"
        @focus="handleCustomAmountFocus"
      />
    </div>
  </div>
</template>

<script>
import { numericOnly } from '@/directives/numericOnly'
import { mask } from 'vue-the-mask'

const ALLOWED_AMOUNTS = [15, 35, 50, 100, 250]

const buildOptions = (sortDesc = false) => {
  return ALLOWED_AMOUNTS.sort((a, b) => (sortDesc ? b - a : a - b)).map(
    (amount) => ({
      id: `monthly-${amount}`,
      value: amount
    })
  )
}

export default {
  name: 'SubscriptionMonthlyAmounts',
  directives: {
    numericOnly,
    mask
  },
  props: {
    updateAmount: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      selectedAmount: null,
      customAmount: null,
      options: buildOptions(true),
      isCustomAmountFocused: false
    }
  },
  methods: {
    handleAmountClick(amount) {
      this.selectedAmount = amount
      this.customAmount = null
      this.isCustomAmountFocused = false
      this.updateAmount(amount)
    },
    handleCustomAmountFocus() {
      this.selectedAmount = null
      this.isCustomAmountFocused = true
    }
  },
  watch: {
    customAmount(value) {
      if (value) {
        this.updateAmount(value.replace('$', ''))
      }
    }
  }
}
</script>

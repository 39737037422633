<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <rect width="24" height="24" rx="12" :fill="fillColor" />
    <path
      d="M9.80396 18.33C15.126 18.33 18.036 13.92 18.036 10.098C18.036 9.972 18.036 9.846 18.03 9.726C18.594 9.318 19.086 8.808 19.476 8.226C18.96 8.454 18.402 8.61 17.814 8.682C18.414 8.322 18.87 7.758 19.086 7.08C18.528 7.41 17.91 7.65 17.25 7.782C16.722 7.218 15.972 6.87 15.138 6.87C13.542 6.87 12.246 8.166 12.246 9.762C12.246 9.99 12.27 10.212 12.324 10.422C9.91796 10.302 7.78796 9.15 6.35996 7.398C6.11396 7.824 5.96996 8.322 5.96996 8.85C5.96996 9.852 6.47996 10.74 7.25996 11.256C6.78596 11.244 6.34196 11.112 5.95196 10.896C5.95196 10.908 5.95196 10.92 5.95196 10.932C5.95196 12.336 6.94796 13.5 8.27396 13.77C8.03396 13.836 7.77596 13.872 7.51196 13.872C7.32596 13.872 7.14596 13.854 6.96596 13.818C7.33196 14.97 8.39996 15.804 9.66596 15.828C8.67596 16.602 7.42796 17.064 6.07196 17.064C5.83796 17.064 5.60996 17.052 5.38196 17.022C6.64796 17.85 8.17196 18.33 9.80396 18.33Z"
      :fill="logoColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTwitter',
  props: {
    logoColor: {
      type: String,
      default: '#2A415B'
    },
    fillColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

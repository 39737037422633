<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <rect width="24" height="24" rx="12" :fill="fillColor" />
    <path
      d="M18.7116 8.63374C18.5502 8.03407 18.0774 7.56126 17.4777 7.39981C16.3822 7.09998 12 7.09998 12 7.09998C12 7.09998 7.61778 7.09998 6.52223 7.38828C5.9341 7.54973 5.44975 8.03407 5.2883 8.63374C5 9.72929 5 12.0011 5 12.0011C5 12.0011 5 14.2845 5.2883 15.3685C5.44975 15.9681 5.92257 16.441 6.52223 16.6024C7.62931 16.9022 12 16.9022 12 16.9022C12 16.9022 16.3822 16.9022 17.4777 16.6139C18.0774 16.4525 18.5502 15.9797 18.7116 15.38C18.9999 14.2845 18.9999 12.0126 18.9999 12.0126C18.9999 12.0126 19.0115 9.72929 18.7116 8.63374Z"
      :fill="logoColor"
    />
    <path
      d="M10.6046 14.0999L14.2487 12.0011L10.6046 9.90222V14.0999Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconYoutube',
  props: {
    logoColor: {
      type: String,
      default: '#2A415B'
    },
    fillColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'
import ForgotPasswordView from '@/views/ForgotPassword.vue'
import ResetPasswordView from '@/views/ResetPassword.vue'
import { useStorage } from 'vue3-storage'

const storage = useStorage()

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordView,
    meta: { layout: 'login' }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPasswordView,
    meta: { layout: 'login' }
  },
  {
    path: '/donation-history',
    name: 'donation-history',
    component: () => import('@/views/DonationHistoryView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/email-subscriptions',
    name: 'email-subscriptions',
    component: () => import('@/views/EmailSubscriptionsView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { layout: 'login' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/NotFoundView.vue')
  }
]

const baseUrl = process.env.BASE_URL

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})

const isAuthenticated = !!storage.getStorageSync('userData')

router.beforeEach((to, from, next) => {
  const allowedRoutes = ['login', 'forgotPassword', 'resetPassword']

  if (!allowedRoutes.includes(to.name) && !isAuthenticated) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router

import { AnalyticsBrowser } from '@segment/analytics-next';

export class Segment {
  constructor() {
    this.loadAnalytics();
    if (window.isSegmentLoading === undefined) {
      window.isSegmentLoading = false;
    }
    if (window.pendingEvents === undefined) {
      window.pendingEvents = [];
    }
  }

  loadAnalytics() {
    if (window.segmentInstance !== undefined) {
      return window.segmentInstance;
    }

    if (window.isSegmentLoading === true) {
      return;
    }

    window.isSegmentLoading = true;
    AnalyticsBrowser.load({
      writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY, // dynamic, chooses write key specified in .env file
    })
      .then(([response]) => {
        window.segmentInstance = response;
        window.isSegmentLoading = false;
        this.dispatchPendingEvents();
      })
      .catch((e) => {
        console.log('Error loading Segment:', e);
      });
  }

  dispatchPendingEvents() {
    window.pendingEvents.forEach(function (item) {
      this.call(item.type, item.event, item.payload, item.callback);
    }, this);
    window.pendingEvents = [];
  }

  getFullPayload(payload) {
    const segmentData = window.segmentData || {};

    let fullPayload = {
      ...payload,
      ...segmentData,
      client: 'donor-dashboard',
      server_environment: process.env.VUE_APP_SEGMENT_ENVIRONMENT, // Sets the environment based on the string VUE_APP_SEGMENT_ENVIRONMENT in the .env file
    };

    return fullPayload;
  }

  track(event, payload = {}, callback) {
    const data = this.getFullPayload(payload);
    this.call('track', event, data, callback);
  }

  identify(userId, traits = {}, callback) {
    const data = this.getFullPayload(traits);
    this.call('identify', userId, data, callback);
  }

  page(event = null, properties = {}, callback) {
    const data = this.getFullPayload(properties);
    this.call('page', event, data, callback);
  }

  call(type, event, payload = {}, callback) {
    if (window.segmentInstance === undefined) {
      window.pendingEvents.push({
        type: type,
        event: event,
        payload: payload,
        callback: callback,
      });
      return;
    }

    window.segmentInstance[type](event, payload).then(function () {
      if (callback !== undefined) {
        callback();
      }
    });
  }
}

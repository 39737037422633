<template>
  <div
    class="flex flex-col p-5 md:pb-0 mt-5 w-full min-h-screen md:min-h-[693px]"
  >
    <h4 class="text-brand-navy font-extrabold">{{ header }}</h4>
    <div
      class="flex flex-1 flex-grow min-h-[452px] md:min-h-[524px] justify-center items-center w-full"
      v-show="loading && showBaseLoader && !isEditing"
    >
      <BaseLoader />
    </div>
    <form
      v-if="isEditing"
      novalidate
      @submit.prevent
      class="flex flex-col flex-1 flex-grow mt-5 gap-2.5"
    >
      <BaseInput
        id="card_number"
        label="Card Number"
        type="numeric"
        inputMode="numeric"
        placeholder="Enter here"
        v-model="form.card_number"
        v-numeric-only:active="!isEditing"
        maxlength="16"
        @blur="onBlur"
        :error="this.v$.form.card_number?.$errors[0]?.$message"
        :disabled="disabled"
      />
      <BaseInput
        id="expiration_date"
        label="Expiration Date"
        type="text"
        placeholder="MM/YY"
        v-model="form.expiration_date"
        mask="##/##"
        v-numeric-only:active="true"
        maxlength="5"
        @blur="onBlur"
        :error="this.v$.form.expiration_date?.$errors[0]?.$message"
        :disabled="disabled"
      />
      <BaseInput
        id="cvc"
        label="CVV"
        type="password"
        placeholder="Enter here"
        v-model="form.cvc"
        :mask="cvcMask"
        v-numeric-only:active="!isEditing"
        maxlength="4"
        @blur="onBlur"
        :error="this.v$.form.cvc?.$errors[0]?.$message"
        :disabled="disabled"
      />
      <BaseCheckbox
        id="default"
        title="Set as default payment method."
        v-model="form.is_default"
        :disabled="disabled && isDefaultCard"
      >
        <span class="text-brand-navy">Set as default payment method.</span>
      </BaseCheckbox>
    </form>

    <form
      v-else
      novalidate
      @submit.prevent
      :class="{ 'hidden': loading && showBaseLoader }"
      class="flex flex-col flex-1 flex-grow mt-5 gap-2.5"
    >
      <label class="block text-brand-navy text-sm font-medium leading-21 mb-1">Credit Card Number (Required)</label>
      <div :class="{ 'border-red-500': displayCardError }" class="border-[1px] p-[12px] bg-[#F9FAFB] rounded-lg hover:border-brand-navy">
        <div id="card-number"></div>
      </div>
      <span
        id="invalidCard"
        role="alert"
        class="block text-sm text-red-brand font-normal leading-5"
        v-show="displayCardError"
      >
      The card number entered is not valid.
      </span>

      <label class="block text-brand-navy text-sm font-medium leading-21 mb-1">Expiration (Required)</label>
      <div :class="{ 'border-red-500': displayExpError }" class="border-[1px] p-[12px] bg-[#F9FAFB] rounded-lg hover:border-brand-navy">
        <div id="card-expiry"></div>
      </div>
      <span
        role="alert"
        class="block text-sm text-red-brand font-normal leading-5"
        style="display: none;"
        v-show="displayExpError"
      >
      The card expiration is not valid.
    </span>

      <label class="block text-brand-navy text-sm font-medium leading-21 mb-1">CVV Number (Required)</label>
      <div :class="{ 'border-red-500': displayCvcError }" class="border-[1px] p-[12px] bg-[#F9FAFB] rounded-lg hover:border-brand-navy">
        <div id="card-cvc"></div>
      </div>
      <span
        role="alert"
        class="block text-sm text-red-brand font-normal leading-5"
        style="display: none;"
        v-show="displayCvcError"
      >
      The card cvv is not valid.
    </span>

      <BaseCheckbox
        id="default"
        title="Set as default payment method."
        v-model="form.is_default"
        :disabled="disabled && isDefaultCard"
      >
        <span class="text-brand-navy">Set as default payment method.</span>
      </BaseCheckbox>
    </form>

    <div class="flex-0 w-full items-center mb-28 md:mb-10">
      <BaseButton
        id="delete-card"
        class="py-[14px] tracking-1 mb-5"
        variant="outlined"
        :disabled="loading"
        @click="handleDelete"
        v-if="isEditing && !isDefaultCard"
      >
        Delete Payment
      </BaseButton>
      <BaseButton
        id="save-card"
        class="py-[14px] tracking-1"
        :loading="loading"
        :disabled="loading"
        @click="handleSave"
      >
        Save
      </BaseButton>
      <button class="hidden" id="removeCardError" @click="removeCardError"></button>
      <button class="hidden" id="removeCVVError" @click="removeCvvError"></button>
      <button class="hidden" id="removeExpError" @click="removeExpError"></button>

      <button class="hidden" id="addCardError" @click="addCardError"></button>
      <button class="hidden" id="addCVVError" @click="addCvvError"></button>
      <button class="hidden" id="addExpError" @click="addExpError"></button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import BaseInput from '@/components/Base/BaseInput.vue'
import BaseCheckbox from '@/components/Base/BaseCheckbox.vue'
import { numericOnly } from '@/directives/numericOnly'
import useVuelidate from '@vuelidate/core'
import { required, helpers, minLength, maxLength } from '@vuelidate/validators'
import {getClientSecret, postCards, postSetCardAsDefault} from '@/api/cards'
import BaseLoader from "@/components/Base/BaseLoader";

export default {
  name: 'SubscriptionPaymentMethod',
  directives: {
    numericOnly
  },
  components: {BaseLoader, BaseButton, BaseInput, BaseCheckbox },
  props: {
    subscriptionId: {
      type: String,
      required: true
    },
    isCuddlyClub: {
      type: Boolean,
      required: true
    },
    action: {
      type: String,
      required: true,
      validator: (value) => {
        return ['adding', 'editing'].includes(value)
      }
    },
    card: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      showBaseLoader: true,
      form: {
        card_number: '',
        cardholder_name: '',
        expiration_date: '',
        cvc: '',
        is_default: false
      },
      v$: useVuelidate(),
      cvcMask: '####',
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      stripe: '',
      correctedValueForIsCuddlyClub: false,
      displayCardError: false,
      displayCvcError: false,
      displayExpError: false
    }
  },
  async mounted() {
    if (this.action === 'editing' && Object.keys(this.card).length > 0) {
      this.form = {
        card_number: this.getMaskedCardNumber(this.card.last4),
        cardholder_name: this.card.name || 'N/A',
        expiration_date: this.getExpirationDate(
          this.card.exp_month,
          this.card.exp_year
        ),
        is_default: this.card.is_default,
        cvc: this.getMaskedCVC(this.card.brand)
      }
      this.cvcMask = this.form.cvc
    } else {
      this.loading = true

      let stripeJsScript = document.createElement('script')
      stripeJsScript.setAttribute('src', 'https://js.stripe.com/v3')
      document.head.appendChild(stripeJsScript)

      const style = {
        base: {
          color: '#4D4D4D',
          fontFamily: "'Lato', sans-serif",
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#CCCCCC',
          },
        },
        invalid: {
          color: '#8C0005',
          iconColor: '#8C0005',
        },
        complete: {
          color: '#4D4D4D'
        }
      };

      const payloadForStripe = {
        isClub: this.isCuddlyClub,
        subscriptionId: this.subscriptionId
      }

      const clientSecretRes = await getClientSecret(payloadForStripe);

      this.correctedValueForIsCuddlyClub = clientSecretRes.data.isClub

      const clientSecret = clientSecretRes.data.stripeData.client_secret

      const publishableKey = clientSecretRes.data.isClub ? process.env.VUE_APP_STRIPE_CLUB_PUBLISHABLE_KEY : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY

      this.stripe = Stripe(publishableKey);

      const elements = this.stripe.elements({
        clientSecret: clientSecret
      });

      this.cardNumber = elements.create('cardNumber', { style });
      this.cardNumber.mount('#card-number');

      this.cardExpiry = elements.create('cardExpiry', { style });
      this.cardExpiry.mount('#card-expiry');

      this.cardCvc = elements.create('cardCvc', { style });
      this.cardCvc.mount('#card-cvc');

      this.cardCvc.on('change', function(event) {
        if (event.complete) {
          document.getElementById('removeCVVError').click();
        } else if (event.error) {
          document.getElementById('addCVVError').click();
        }
      });

      this.cardExpiry.on('change', function(event) {
        if (event.complete) {
          document.getElementById('removeExpError').click();
        } else if (event.error) {
          document.getElementById('addExpError').click();
        }
      });

      this.cardNumber.on('change', function(event) {
        if (event.complete) {
          document.getElementById('removeCardError').click();
        } else if (event.error) {
          document.getElementById('addCardError').click();
        }
      });

      this.loading = false
      this.showBaseLoader = false
    }
  },
  unmounted() {
    if (this.action !== 'editing') {
      this.cardNumber.unmount();
      this.cardExpiry.unmount();
      this.cardCvc.unmount();
    }
  },
  methods: {
    removeCvvError() {
      this.displayCvcError = false;
    },
    removeCardError() {
      this.displayCardError = false;
    },
    removeExpError() {
      this.displayExpError = false;
    },
    addCvvError() {
      this.displayCvcError = true;
    },
    addCardError() {
      this.displayCardError = true;
    },
    addExpError() {
      this.displayExpError = true;
    },
    onBlur(e) {
      this.v$.form[e.target.id].$touch()
    },
    getPayload() {
      const { expiration_date: expirationDate, ...payload } = this.form
      const [month, year] = expirationDate.split('/')
      payload.exp_month = month
      payload.exp_year = year
      payload.subscription_id = this.subscriptionId
      payload.card_number = payload.card_number.replace(/\s/g, '')
      payload.isCuddlyClub = this.isCuddlyClub

      return payload
    },
    getPayloadForStripeEmbedded(cardToken) {
      return {
        isCuddlyClub: this.correctedValueForIsCuddlyClub,
        cardToken: cardToken,
        subscription_id: this.subscriptionId
      }
    },
    getEmbeddedStripePayload() {
      return {
        cardNumber: this.cardNumber,
        cardExpiry: this.cardExpiry,
        cardCvc: this.cardCvc,
      }
    },
    async handleAdd() {
      this.loading = true

      const { token, error } = await this.stripe.createToken(this.cardNumber)

      if (error) {
        this.loading = false;

        return;
      }

      const payload = this.getPayloadForStripeEmbedded(token.id)

      const { status, data, errors } = await postCards(payload)

      if (status >= 500) {
        this.$toast.error('Whoops, looks like something went wrong.')
        this.loading = false

        return
      } else if (status >= 400 && status < 500) {
        const errMessage = errors?.errors
          ? errors.errors
          : 'Whoops, looks like something went wrong.'
        this.$toast.error(errMessage)
        this.loading = false

        return
      }

      if (this.form.is_default) {
        await this.handleSetCardAsDefault(data)
      }

      this.$toast.success('Card added successfully.')
      this.emitter.emit('reload-cards')
    },
    async handleEdit() {
      if (this.isDefaultCard) {
        this.$toast.error('You cannot edit your default card.')

        return
      }

      if (this.form.is_default) {
        const payload = {
          isCuddlyClub: this.isCuddlyClub,
          customer_id: this.card.customer,
          card_id: this.card.id
        }

        this.loading = true
        const { status, data } = await postSetCardAsDefault(payload)

        if (status >= 400) {
          this.$toast.error('Whoops, looks like something went wrong.')
        } else {
          this.$toast.success('Card updated successfully.')
          this.emitter.emit('reload-cards')
          this.emitter.emit('reload-subscription', { card: data })
        }

        this.loading = false
      }
    },
    async handleDelete() {
      if (this.isDefaultCard) {
        this.$toast.error('You cannot delete your default card.')

        return
      }

      this.emitter.emit('delete-card', { card: this.card })
    },
    async handleSave() {
      if (this.action === 'editing') {
        await this.handleEdit()
      } else {
        await this.handleAdd()
      }
    },
    async handleSetCardAsDefault(card) {
      const { customer, cardToken, ...payload } = card
      payload.customer_id = customer
      payload.card_id = cardToken
      const { status, data } = await postSetCardAsDefault(payload)

      if (status >= 400) {
        this.$toast.error('Whoops, looks like something went wrong.')

        return
      }

      this.emitter.emit('reload-subscription', { card: data })
    },
    getMaskedCardNumber(last4) {
      return `************${last4}`
    },
    getExpirationDate(month, year) {
      return `${month.toString().padStart(2, '0')}/${year
        .toString()
        .substring(2)}`
    },
    getMaskedCVC(brand) {
      return brand === 'American Express' ? '****' : '***'
    }
  },
  computed: {
    disabled() {
      return this.loading || this.action === 'editing'
    },
    isEditing() {
      return this.action === 'editing'
    },
    header() {
      return this.isEditing ? 'Edit Payment' : 'Add a New Payment'
    },
    isDefaultCard() {
      return this.card.is_default
    }
  },
  validations: {
    form: {
      card_number: {
        required: helpers.withMessage('Card number is required.', required),
        minLength: helpers.withMessage(
          'Card number must be at least 15 characters.',
          minLength(15)
        ),
        maxLength: helpers.withMessage(
          'Card number must be at most 16 characters.',
          maxLength(16)
        )
      },
      cardholder_name: {
        required: helpers.withMessage('Cardholder name is required.', required)
      },
      expiration_date: {
        required: helpers.withMessage('Expiration date is required.', required),
        minLength: helpers.withMessage(
          'Expiration date must be at least 4 characters.',
          function (value) {
            return value.replace(/\//g, '').length >= 4
          }
        ),
        format: helpers.withMessage(
          'Expiration date is invalid.',
          function (value) {
            const [month, year] = value.split('/')
            const currentYear = new Date().getFullYear().toString().substring(2)
            const currentMonth = new Date().getMonth() + 1

            return (
              month >= 1 &&
              month <= 12 &&
              year >= currentYear &&
              (year > currentYear || month >= currentMonth)
            )
          }
        )
      },
      cvc: {
        required: helpers.withMessage('CVV is required.', required),
        minLength: helpers.withMessage(
          'CVV must be at least 3 characters.',
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          'CVV must be at most 4 characters.',
          maxLength(4)
        )
      }
    }
  }
}
</script>

<template>
  <div
    class="flex flex-col flex-shrink w-auto md:max-w-[320px] min-h-[521px] bg-white shadow-1 rounded-lg md:mx-auto"
  >
    <div class="p-5 w-full h-full">
      <h6 class="text-brand-navy cursor-pointer" @click="goToStoryPage">
        {{ story.name }}
      </h6>
      <div class="flex my-5 gap-[4px] items-center">
        <template v-if="story?.last_update">
          <BaseIcon name="clock" class="w-5 h-5 text-gray-300" />
          <span class="body-text-4 text-gray-700">
            Last Update: {{ formatDate(story.last_update, 'MMM DD, YYYY') }}
          </span>
        </template>
      </div>
      <div class="w-full h-[240px] cursor-pointer" @click="goToStoryPage">
        <img
          :src="story.image"
          :alt="story.name"
          class="w-full h-full object-cover"
          @error="handleBrokenImage"
        />
      </div>
      <div
        class="mt-5 mb-[22px] min-h-[84px] cursor-pointer"
        @click="goToStoryPage"
      >
        <span class="line-clamp-4 body-text-4">{{ story.description }}</span>
      </div>
      <div class="flex gap-[6px] items-center h-5">
        <BaseIcon name="home" class="w-4 h-4 text-brand-pink"></BaseIcon>
        <span class="detail-3 text-gray-700 mt-0.5">{{ story.shelter }}</span>
      </div>
      <div class="flex h-[38px] gap-[13.68px] mt-[22px]">
        <BaseButton
          :id="getDonateButtonId"
          @click="donate"
          class="text-xs leading-14 tracking-[1px]"
        >
          Donate
        </BaseButton>
        <BaseButton
          :id="getShareButtonId"
          @click="share"
          variant="outlined"
          class="text-xs leading-14 tracking-[1px] font-bold border-secondary-steel"
        >
          Share
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton'
import BaseIcon from '@/components/Base/BaseIcon'
import date from '@/mixins/date'
import campaign from '@/mixins/campaign'
import image from '@/mixins/image'

export default {
  name: 'DashboardSuccessStoryCard',
  components: {
    BaseButton,
    BaseIcon
  },
  mixins: [date, campaign, image],
  props: {
    story: {
      type: Object,
      required: true
    }
  },
  computed: {
    getDonateButtonId() {
      return `donate-${this.story?.id}`
    },
    getShareButtonId() {
      return `share-${this.story?.id}`
    }
  },
  methods: {
    donate() {
      window.open(this.getCampaignUrl(this.story), '_blank', 'noreferrer')
    },
    share() {
      this.emitter.emit('share', this.story)
    },
    goToStoryPage() {
      const url = this.getSuccessStoryUrl(this.story)
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<template>
  <dialog class="fixed top-0 left-0 z-50" :open="open" @close="handleClose">
    <div
      enter="ease-out duration-300"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="ease-in duration-200"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-center justify-center p-0 md:p-4 text-center"
      >
        <div
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 pt-4 pr-4" v-if="dismissible">
              <button
                type="button"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                @click="handleClose"
              >
                <span class="sr-only">Close</span>
                <BaseIcon name="close" class="h-6 w-6" />
              </button>
            </div>
            <div class="w-full z-50">
              <div class="relative block mt-3 text-center sm:mt-5">
                <div
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                  v-if="title"
                >
                  {{ title }}
                </div>
                <div class="relative mt-2">
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </dialog>
</template>

<script>
import BaseIcon from './BaseIcon.vue'

export default {
  name: 'BaseDialog',
  components: {
    BaseIcon
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

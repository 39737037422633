<template>
  <div class="w-full border-none p-0 bg-transparent rounded-md">
    <label
      class="block text-gray-900 text-sm font-medium leading-21 mb-2 cursor-pointer"
      :class="{ 'text-red-500': error }"
      :for="id"
      v-if="label"
    >
      <span class="inline-block">
        <span>
          {{ label }}
        </span>
        <span class="ml-1 text-sm font-normal leading-21 text-gray-500">
          {{ additionalInfo }}
        </span>
        <span v-if="required" class="ml-1 font-semibold">*</span>
      </span>
    </label>

    <div class="relative">
      <input
        :id="id"
        :name="id"
        :data-testid="id"
        class="w-full h-[42px] block px-3 py-2 appearance-none border rounded-md shadow-sm focus:outline-none text-sm focus:ring-1 focus:ring-black focus:border-black"
        :placeholder="placeholder"
        :class="{
          'text-red-700 border-red-500 bg-red-50 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
            error,
          [`border-gray-300 ${inputBackgroundColorClass} placeholder-gray-500  focus:ring-black focus:border-black`]:
            !error,
          'bg-gray-100': disabled
        }"
        :required="required"
        :value="modelValue"
        v-bind="$attrs"
        v-model="inputValue"
        @input="handleInput"
        :autocomplete="autocompleteOff"
        v-mask="mask"
        :disabled="disabled"
        :title="label"
        ref="input"
        v-if="hasMask"
      />
      <input
        :id="id"
        :name="id"
        :data-testid="id"
        class="w-full h-[42px] bg-white block px-3 py-2 appearance-none border rounded-md shadow-sm focus:outline-none text-sm focus:ring-1 focus:ring-black focus:border-black"
        :placeholder="placeholder"
        :class="{
          'text-red-700 border-red-500 bg-red-50 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
            error,
          [`border-gray-300 ${inputBackgroundColorClass} placeholder-gray-500  focus:ring-black focus:border-black`]:
            !error,
          'bg-gray-100': disabled,
          'animate-pulse bg-gray-100': loading,
          'pl-9': trailingAddonIconPosition === 'left'
        }"
        :required="required"
        :value="modelValue"
        v-bind="$attrs"
        v-model="inputValue"
        @input="handleInput"
        :autocomplete="autocompleteOff"
        :disabled="disabled"
        :title="label"
        ref="input"
        v-else
      />
      <div
        class="absolute bg-transparent inset-y-0 flex items-center -ml-px"
        :class="{
          'right-0 pr-3': trailingAddonIconPosition === 'right',
          'left-0 pl-3': trailingAddonIconPosition === 'left'
        }"
        v-if="trailingAddonText || trailingAddonIcon || hasSlot"
      >
        <span
          v-if="trailingAddonText"
          class="text-sm font-normal leading-21 text-brand-navy"
        >
          {{ trailingAddonText }}
        </span>

        <slot name="trailingAddonComponent" />

        <BaseIcon
          :name="trailingAddonIcon"
          v-if="trailingAddonIcon"
          class="w-4 h-4 text-color-inherit"
        />
      </div>
    </div>
    <span
      role="alert"
      class="block text-sm text-red-600 font-normal leading-5"
      v-if="error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
import BaseIcon from './BaseIcon.vue'
import { mask } from 'vue-the-mask'

export default {
  name: 'BaseInput',
  components: {
    BaseIcon
  },
  directives: {
    mask
  },
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: [String, Boolean],
      default: ''
    },
    additionalInfo: {
      type: String,
      default: ''
    },
    trailingAddonText: {
      type: String,
      default: ''
    },
    trailingAddonIcon: {
      type: String,
      default: ''
    },
    trailingAddonIconPosition: {
      type: String,
      default: 'right'
    },
    mask: {
      type: [String, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    inputBackgroundColorClass: {
      type: String,
      default: 'bg-gray-50'
    }
  },
  data() {
    return {
      inputValue: this.modelValue || ''
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.inputValue)
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
  beforeMount() {
    if (this.id === undefined) {
      throw new Error('BaseInput requires an id')
    }
  },
  computed: {
    autocompleteOff() {
      return Math.random().toString(36).substring(2, 15)
    },
    hasMask() {
      return this.mask !== ''
    },
    hasSlot() {
      return this.$slots.trailingAddonComponent !== undefined
    }
  }
}
</script>

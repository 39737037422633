<template>
  <svg
    viewBox="0 0 32 32"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="0"
    v-bind="$attrs"
  >
    <path
      d="M9.822,6.302c-0.391-0.394-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.032,0,1.426L16.601,16l-8.193,8.272   c-0.391,0.394-0.391,1.032,0,1.426s1.024,0.394,1.414,0l8.899-8.985c0.215-0.215,0.301-0.492,0.279-0.787   c-0.017-0.229-0.115-0.475-0.279-0.64L9.822,6.302z"
      fill="#121313"
    />
    <path
      d="M23.016,6.003c-0.552,0-1,0.448-1,1v17.969c0,0.552,0.448,1,1,1s1-0.448,1-1V7.003   C24.016,6.451,23.568,6.003,23.016,6.003z"
      fill="#121313"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronLast'
}
</script>

<template>
  <div class="grid grid-cols-3 gap-5">
    <DashboardMetricCard
      icon="donations-total"
      customClass="w-7 md:w-[48px] h-7 md:h-[48px]"
      :value="dashboardMetrics.total_donations"
      title="Total Donations"
      :metrics="totalDonations"
      :loading="loading"
      :as-currency="true"
    />
    <DashboardMetricCard
      icon="products-purchased"
      customClass="w-[30px] md:w-[50px] h-[30px] md:h-[50px]"
      :value="dashboardMetrics.products_purchased"
      title="Products Purchased"
      :metrics="productsPurchased"
      :loading="loading"
    />
    <DashboardMetricCard
      icon="animals-helped"
      customClass="w-[30px] md:w-[50px] h-[30px] md:h-[50px]"
      :value="dashboardMetrics.animals_helped"
      title="Animals Helped"
      :metrics="animalsHelped"
      :loading="loading"
    />
  </div>
</template>

<script>
import DashboardMetricCard from './DashboardMetricCard.vue'
import { getDashboardBarMetrics, getDashboardMetrics } from '@/api/donor'
import { abortController } from '@/mixins/abortController'
import {donorLogout} from "@/api/auth";

export default {
  name: 'DashboardMetrics',
  mixins: [abortController],
  components: {
    DashboardMetricCard
  },
  computed: {
    totalDonations() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Monetary',
          percentage: this.dashboardBarMetrics.monetary_donations
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Wish List',
          percentage: this.dashboardBarMetrics.wishlist_donations
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Subscription',
          percentage: this.dashboardBarMetrics.subscription_donations
        },
        {
          bgClass: 'bg-secondary-steel',
          title: 'Wish List Monetary Donations',
          percentage: this.dashboardBarMetrics.wishlist_monetary_donations
        },
        {
          bgClass: 'bg-secondary-otd',
          title: 'One-Time Donations',
          percentage: this.dashboardBarMetrics.one_time_donations
        }
      ]
    },
    productsPurchased() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Food',
          percentage: this.dashboardBarMetrics.food
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Health',
          percentage: this.dashboardBarMetrics.health
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Others',
          percentage: this.dashboardBarMetrics.other_tags
        }
      ]
    },
    animalsHelped() {
      return [
        {
          bgClass: 'bg-secondary-red',
          title: 'Cats',
          percentage: this.dashboardBarMetrics.cats
        },
        {
          bgClass: 'bg-secondary-sand',
          title: 'Dogs',
          percentage: this.dashboardBarMetrics.dogs
        },
        {
          bgClass: 'bg-secondary-light-blue',
          title: 'Others',
          percentage: this.dashboardBarMetrics.other_animals
        }
      ]
    }
  },
  methods: {
    async logoutDueToBeingUnAuthenticated() {
        this.$store.dispatch('afterSuccessLogin', null)
        this.$storage.removeStorageSync('userData')
        this.$router.go()
    }
  },
  data() {
    const userInfo = this.$store.getters.getUser

    return {
      dashboardMetrics: {
        animals_helped: 0,
        products_purchased: 0,
        total_donations: 0
      },
      dashboardBarMetrics: {
        cats: 0,
        dogs: 0,
        food: 0,
        health: 0,
        monetary_donations: 0,
        other_animals: 0,
        other_tags: 0,
        subscription_donations: 0,
        wishlist_donations: 0,
        wishlist_monetary_donations: 0,
        one_time_donations: 0
      },
      loading: true,
      donorName: userInfo.name ? userInfo.name : 'Donor'
    }
  },
  async beforeCreate() {
    const { status, data } = await getDashboardBarMetrics()

    if (status === 200) {
      this.dashboardBarMetrics = { ...data }
    } else if (status === 401) {
      await this.logoutDueToBeingUnAuthenticated()
    }
  },
  async created() {
    const { status, data } = await getDashboardMetrics()

    if (status === 200) {
      this.dashboardMetrics = { ...data }
    }

    this.loading = false
  }
}
</script>

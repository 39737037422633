<template>
  <svg
    viewBox="0 0 32 32"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    stroke-width="0"
    v-bind="$attrs"
  >
    <path
      clip-rule="evenodd"
      d="M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z"
      fill="#121313"
      fill-rule="evenodd"
      id="Chevron_Right"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronRight'
}
</script>

<template>
  <div class="flex flex-col p-5 mt-4">
    <h4 class="text-brand-navy mt-2">
      Your last payment for {{ subscriptionType }} Subscription is
      <span class="text-brand-pink">{{ currentAmount }}</span
      >.
    </h4>
    <SubscriptionMonthlyAmounts
      v-if="showStripeAmountChangeOption"
      :updateAmount="updateAmount"
    />
  </div>
  <div
    class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
  >
    <BaseButton
      id="save"
      class="py-[18px] px-[66px] md:px-[20px] h-11 tracking-1"
      @click="handleReactivate"
      :loading="loading"
      :disabled="loading"
    >
      Reactivate Now
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import SubscriptionMonthlyAmounts from '@/components/Subscription/SubscriptionMonthlyAmounts.vue'
import currency from '@/mixins/currency'
import {
  resumeSubscription,
  updateDonorSubscription
} from '@/api/subscriptions'

export default {
  name: 'SubscriptionReactivateDefault',
  mixins: [currency],
  components: { SubscriptionMonthlyAmounts, BaseButton },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    let amount = this.subscription?.amount || this.subscription?.future_amount || 0
    const reducedAmount = (Math.ceil(amount * 100) - (parseFloat(process.env.VUE_APP_FEE) * 100)) / 100

    amount = Number.isInteger(reducedAmount) ? reducedAmount : amount;

    amount = this.formatCurrency(
        amount,
      { decimals: false }
    )

    return {
      currentAmount: amount,
      selectedAmount: null,
      loading: false
    }
  },
  methods: {
    handleDismiss() {
      this.$store.commit('setIsSubscriptionDetailsOpen', false)
    },
    updateAmount(amount) {
      this.selectedAmount = amount
    },
    async handleReactivate() {
      if (!this.selectedAmount && this.subscription.method === 'stripe') {
        this.$toast.error(
          'Please select an amount to reactivate your subscription.'
        )
        return
      }

      this.loading = true

      const payload = {
        subscription_id: this.subscription.subscription_id
      }

      const { status, data, errors } = await resumeSubscription(payload)

      if (status >= 200 && status < 300) {
        const updateSubPayload = {
          amount: this.selectedAmount * 100, // to cents
          subscriptionId: this.subscription.subscription_id
        }

        if (this.subscription.method === 'stripe') {
          await this.updateSubscriptionAmount(updateSubPayload)
        } else {
          this.emitter.emit('subscription-reactivate_reactivated', {
            reactivated: true
          })
        }
      } else {
        // handle errors
        const error =
          'There was an error trying to reactivate the subscription.'
        this.emitter.emit('subscription-reactivate_failed', { error })
        this.$toast.error(error)
      }

      this.loading = false
    },
    async updateSubscriptionAmount(updateSubPayload) {
      const { status, data, errors } = await updateDonorSubscription(
        updateSubPayload
      )

      if (status >= 200 && status < 300) {
        this.emitter.emit('subscription-reactivate_reactivated', {
          reactivated: true
        })
      } else {
        // second display of errors. Resume was successful but updating the amount was not
        const error =
          'There was an error trying to change the subscription amount.'
        this.emitter.emit('subscription-reactivate_failed', { error })
        this.$toast.error(error)
      }
    }
  },

  computed: {
    showStripeAmountChangeOption() {
      return this.subscription.method === 'stripe'
    },
    amount() {
      const amount =
        this.subscription?.amount || this.subscription?.future_amount || 0

      return this.formatCurrency(Math.floor(amount), { decimals: false })
    },
    subscriptionType() {
      return this.subscription.type === 'fullbowls'
        ? 'Full Bowls Club'
        : 'CUDDLY Club'
    }
  }
}
</script>

<style scoped></style>

<template>
  <template v-if="editSubscription">
    <SubscriptionDetails
      :subscription="subscriptionForDetails"
      :onDismiss="handleDismiss"
    />
  </template>
  <template v-if="reactivateSubscription">
    <SubscriptionReactivate
      :subscription="subscriptionForDetails"
      :onDismiss="handleDismiss"
    />
  </template>

  <div
    class="flex flex-col xl:flex-row gap-5"
    :class="{
      'hidden md:block': reactivateSubscription,
      hidden: editSubscription
    }"
  >
    <div class="flex flex-col flex-1 flex-grow w-full">
      <div class="w-full">
        <DashboardWelcomeBack />
        <DashboardUpdate />
      </div>
      <div class="w-full mb-10 px-5 md:px-0">
        <DashboardMetrics />
      </div>
      <DashboardSubscriptions
        :onEdit="handleEdit"
        :onReactivate="handleReactivate"
        @hasSubscription="updateSubscribedDonorValue"
      />
      <div class="w-full px-5 md:px-0 md:mb-10">
        <DashboardCampaignsDonated />
      </div>
    </div>
  </div>
  <DashboardFooter />
</template>

<script>
import DashboardWelcomeBack from '@/components/Dashboard/DashboardWelcomeBack.vue'
import DashboardMetrics from '@/components/Dashboard/DashboardMetrics.vue'
import DashboardCampaignsDonated from '@/components/Dashboard/DashboardCampaignsDonated.vue'
import DashboardFooter from '@/components/Dashboard/DashboardFooter.vue'
import DashboardSubscriptions from '@/components/Dashboard/DashboardSubscriptions.vue'
import SubscriptionDetails from '@/components/Subscription/SubscriptionDetails.vue'
import SubscriptionReactivate from '@/components/Subscription/SubscriptionReactivate/SubscriptionReactivate.vue'
import { abortController } from '@/mixins/abortController'
import DashboardUpdate from '@/components/Dashboard/DashboardUpdate.vue'

export default {
  name: 'DashboardView',
  ...abortController,
  components: {
    DashboardUpdate,
    DashboardWelcomeBack,
    DashboardMetrics,
    DashboardFooter,
    DashboardSubscriptions,
    DashboardCampaignsDonated,
    SubscriptionDetails,
    SubscriptionReactivate
  },
  data() {
    return {
      editSubscription: false,
      reactivateSubscription: false,
      isSubscribed: false,
      subscriptionForDetails: {}
    }
  },
  methods: {
    handleEdit(subscription) {
      this.editSubscription = true
      this.reactivateSubscription = false
      this.subscriptionForDetails = subscription
    },
    handleReactivate(subscription) {
      this.reactivateSubscription = true
      this.editSubscription = false
      this.subscriptionForDetails = subscription
    },
    handleDismiss() {
      this.editSubscription = false
      this.reactivateSubscription = false
    },
    updateSubscribedDonorValue(value) {
      this.isSubscribed = value
    }
  }
}
</script>

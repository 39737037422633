<template>
  <div
    class="relative w-full md:max-w-[390px] bg-white flex flex-col lg:flex-row top-16 md:shadow-2 md:rounded-2xl md:mx-auto overflow-hidden"
    :class="{
      'min-h-screen md:min-h-[693px]': !deleting,
      'min-h-screen md:min-h-0 md:max-h-[288px] md:h-[288px]': deleting
    }"
  >
    <div class="flex flex-col w-full h-full items-start">
      <div class="absolute top-0 md:right-0 p-5 pl-[18px] pt-2.5 md:pr-5">
        <BaseIcon
          name="arrow-left"
          class="md:hidden h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
        />
        <BaseIcon
          name="close"
          class="hidden md:block h-6 w-6 text-brand-navy cursor-pointer"
          @click="handleBack"
        />
      </div>
      <SubscriptionPaymentMethod
        v-if="adding || editing"
        :subscription-id="subscriptionId"
        :is-cuddly-club="isCuddlyClub"
        :action="action"
        :card="card"
      />
      <SubscriptionPaymentDelete
        v-else-if="deleting"
        :subscription-id="subscriptionId"
        :card="card"
      />
      <SubscriptionPaymentDefault v-else :subscription-id="subscriptionId" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import SubscriptionPaymentDefault from '@/components/Subscription/SubscriptionPayment/SubscriptionPaymentDefault.vue'
import SubscriptionPaymentMethod from '@/components/Subscription/SubscriptionPayment/SubscriptionPaymentMethod.vue'
import scrollTop from '@/mixins/scrollTop'
import SubscriptionPaymentDelete from '@/components/Subscription/SubscriptionPayment/SubscriptionPaymentDelete.vue'

export default {
  name: 'SubscriptionPayment',
  mixins: [scrollTop],
  components: {
    SubscriptionPaymentDelete,
    BaseIcon,
    SubscriptionPaymentDefault,
    SubscriptionPaymentMethod
  },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      adding: false,
      editing: false,
      deleting: false,
      action: '',
      card: {}
    }
  },
  created() {
    this.scrollTop()
    this.emitter.on('add-card', () => {
      this.scrollTop()
      this.adding = true
      this.action = 'adding'
    })
    this.emitter.on('edit-card', ({ card }) => {
      this.scrollTop()
      this.editing = true
      this.action = 'editing'
      this.card = card
    })
    this.emitter.on('delete-card', ({ card }) => {
      this.scrollTop()
      this.deleting = true
      this.adding = false
      this.editing = false
      this.card = card
    })
    this.emitter.on('reload-cards', () => {
      this.scrollTop()
      this.adding = false
      this.editing = false
      this.deleting = false
    })
  },
  methods: {
    handleBack() {
      this.emitter.emit('back-to-subscription', { reload: false })
    }
  },
  computed: {
    subscriptionId() {
      return this.subscription.subscription_id.toString()
    },
    isCuddlyClub() {
      return this.subscription.type === 'wishpack'
    }
  }
}
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <rect width="24" height="24" rx="12" :fill="fillColor" />
    <path
      d="M15.0568 5H12.6398V14.5362C12.6398 15.6725 11.7103 16.6058 10.5534 16.6058C9.39659 16.6058 8.467 15.6725 8.467 14.5362C8.467 13.4203 9.37594 12.5072 10.4915 12.4667V10.0725C8.03319 10.113 6.05005 12.0812 6.05005 14.5362C6.05005 17.0116 8.0745 19 10.5741 19C13.0736 19 15.0981 16.9913 15.0981 14.5362V9.64637C16.007 10.2957 17.1225 10.6812 18.3 10.7015V8.30725C16.4822 8.24638 15.0568 6.78551 15.0568 5Z"
      :fill="logoColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTikTok',
  props: {
    logoColor: {
      type: String,
      default: '#2A415B'
    },
    fillColor: {
      type: String,
      default: '#ffffff'
    }
  }
}
</script>

<template>
  <div
    class="flex w-full h-[172px] md:h-[212px] justify-center items-center bg-secondary-light-blue"
  >
    <div
      class="relative flex lg:flex-col w-full md:min-w-[380px] lg:max-w-[380px] justify-center items-center"
    >
      <div
        v-if="isFullBowls"
        class="text-brand-navy text-right justify-center flex flex-col"
      >
        <h2 class="font-bold">Full Bowls</h2>
        <h2 class="font-extrabold">Club</h2>
      </div>
      <img
        :alt="subscriptionType"
        src="@/assets/cuddly-club.png"
        class="max-w-[234.51px] max-h-[130px] h-full"
        v-else
      />
    </div>
  </div>
  <div class="flex flex-col w-full h-auto py-[30px] px-5">
    <h4 class="text-brand-navy">You are all set!</h4>
    <span class="body-text-1 mt-2.5">
      Thank you for being a loyal member of {{ subscriptionType }}. You have
      successfully reactivated the {{ subscriptionType }} subscription. This
      update will take a few minutes to process. In the meantime, feel free to
      explore more on CUDDLY.
    </span>
  </div>
  <div
    class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
  >
    <BaseButton
      id="save"
      class="py-[18px] w-full tracking-2 h-11"
      @click="handleDismiss"
    >
      Done
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  name: 'SubscriptionReactivateReactivated',
  components: { BaseButton },
  props: {
    subscriptionType: {
      type: String,
      required: true
    },
    isFullBowls: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleDismiss() {
      this.emitter.emit('subscriptions-refresh', { reload: true })
      this.$store.commit('setIsSubscriptionReactivateOpen', false)
      this.$emit('dismiss')
    }
  }
}
</script>

import { removeTrailingAndLeadingSlashes } from '@/utils/strings'

const PATHS = {
  ourTeam: '/about-us',
  contact: '/help-center?hcUrl=%2Fen-US%2Fcontact',
  jobs: '/careers',
  changeALife: '/donate/change-a-life',
  urgentCare: '/donate/urgent-needs',
  rescueWishlists: '/wishlists',
  almostFunded: '/donate/almost-funded',
  adoptAPet: '/adopt',
  faqs: '/help-center',
  returnPolicy: '/help-center?hcUrl=%2Fen-US%2Freturn-policy-for-donors-380068',
  privacyPolicy: '/privacy-policy',
  terms: '/terms-conditions'
}

const getBasePages = () => {
  const baseUrl = removeTrailingAndLeadingSlashes(
    process.env.VUE_APP_CUDDLY_URL || '',
    false
  )
  const pages = Object.entries(PATHS).map((path) => {
    const [key, value] = path
    const url = removeTrailingAndLeadingSlashes(value, false)

    return {
      [key]: `${baseUrl}/${url}`
    }
  })

  return { ...Object.assign({}, ...pages) }
}

export const PAGES = {
  ...getBasePages(),
  press: 'https://blog.cuddly.com/press',
  blog: 'https://blog.cuddly.com',
  orderStatus: 'mailto:support@cuddly.com',
  mailTo: 'mailto:support@cuddly.com'
}

import { get, post, put, failedRequest, controller } from '@/config/api'

const getSubscriptions = async () => {
  try {
    const { status, data } = await get('/api/donor/subscriptions', {
      signal: controller.signal
    })

    return { status, data }
  } catch (e) {
    return failedRequest(e)
  }
}

const updateDonorSubscription = async (subscription) => {
  try {
    const payload = {
      amount: subscription.amount,
      subscriptionId: subscription.subscriptionId
    }

    const { status, data, errors } = await put('/api/donor/subscriptions', payload)

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

const pauseSubscription = async (payload) => {
  try {
    const { status, data, errors } = await post(
      '/api/donor/subscriptions/pause',
      payload
    )

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

const resumeSubscription = async (payload) => {
  try {
    const { status, data, errors } = await post(
      '/api/donor/subscriptions/resume',
      payload
    )

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

const cancelSubscription = async (payload) => {
  try {
    const { status, data, errors } = await put(
      '/api/donor/subscriptions/cancel',
      payload
    )

    return { status, data, errors }
  } catch (e) {
    return failedRequest(e)
  }
}

export {
  getSubscriptions,
  updateDonorSubscription,
  pauseSubscription,
  cancelSubscription,
  resumeSubscription
}

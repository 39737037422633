export const removeTrailingSlash = (value, single = true) => {
  if (typeof value !== 'string') return value

  return single ? value.replace(/\/$/, '') : value.replace(/\/+$/, '')
}

export const removeLeadingSlash = (value, single = true) => {
  if (typeof value !== 'string') return value

  return single ? value.replace(/^\//, '') : value.replace(/^\/*/, '')
}

export const removeTrailingAndLeadingSlashes = (value, single = true) => {
  return removeTrailingSlash(removeLeadingSlash(value, single), single)
}

<template>
  <svg
    id="wave"
    viewBox="0 0 1440 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style="
      transform: rotate(180deg);
      transition: all 0.3s ease 0s;
      fill: #2a415b;
    "
  >
    <defs>
      <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
        <stop stop-color="rgba(42, 65, 91, 1)" offset="0%"></stop>
        <stop stop-color="rgba(42, 65, 91, 1)" offset="100%"></stop>
      </linearGradient>
    </defs>
    <path
      fill="#2a415b"
      d="M0 0V96.9609C0 96.9609 57.7412 116.302 320.621 57.3379C491.192 19.0786 591.951 32.3374 682.806 44.293C731.974 50.7627 778.24 56.8511 831.102 54.186C945 48.4438 1043 43.7427 1183.62 64.4097C1394.2 95.3599 1440 71.8242 1440 71.8242V0H0Z"
      style="transform: translate(0px, 0px); opacity: 1"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'IconWave'
}
</script>

<template>
  <div class="flex flex-col p-5 mt-8">
    <span class="body-text-1 mb-10 text-gray-700">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionCancelFailed',
  props: {
    error: {
      type: String,
      default: ''
    }
  }
}
</script>

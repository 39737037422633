import axios from 'axios'
import store from '@/store'

const token = store.getters.getUser?.token || ''
let controller = new AbortController()

const refreshController = () => {
  controller = new AbortController()
}

const failedRequest = (error) => {
  if (error?.code === 'ERR_CANCELED') {
    return { status: 500, canceled: true }
  }

  if (Object.hasOwn(error, 'response') === false) {
    return { status: 500, canceled: true }
  }

  const { status, data } = error.response

  return { status, errors: data }
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BACKEND,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined
  }
})

api.interceptors.request.use(
  (config) => {
    store.commit('setLoading', true)

    return config
  },
  (error) => {
    store.commit('setLoading', false)

    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    store.commit('setLoading', false)

    return response
  },
  (error) => {
    store.commit('setLoading', false)

    return Promise.reject(error)
  }
)

const { get, post, put, delete: destroy } = api

export { get, post, put, destroy, failedRequest, controller, refreshController }

export default api

<template>
  <footer
    v-bind="$attrs"
    class="absolute left-0 bottom-0 w-full"
    :class="{
      'hidden md:block':
        isSubscriptionReactivateOpen && !isSubscriptionDetailsOpen,
      hidden: isSubscriptionDetailsOpen
    }"
  >
    <div
      class="footer bg-brand-navy absolute w-full h-[744px] lg:max-h-[498px] flex flex-col"
    >
      <div class="bg-body -mt-1 relative h-[80px] xl:h-[120px]">
        <BaseIcon name="wave" class="absolute top-0 mt-4" />
      </div>
      <div
        class="flex flex-col text-white h-[714px] lg:h-[320px] mt-[60px] mb-10 mx-10 lg:mx-[100px]"
      >
        <div class="grid grid-cols-1 lg:grid-cols-3 z-10">
          <div class="w-full lg:max-w-[300px]">
            <img
              src="@/assets/cuddly-white.png"
              alt="CUDDLY"
              class="w-[169px] h-[30px]"
            />
            <p class="body-text-4 mt-5">
              CUDDLY is a fundraising and wish list platform that sends much
              needed aid and supplies to verified 501(c)(3) nonprofit animal
              rescues with the intention of helping as many animals as possible.
            </p>
            <div class="flex mt-5 gap-[26px]">
              <BaseIcon
                name="facebook"
                class="cursor-pointer z-10"
                @click="openSocialNetwork('facebook')"
              />
              <BaseIcon
                name="instagram"
                class="cursor-pointer z-10"
                @click="openSocialNetwork('instagram')"
              />
              <BaseIcon
                name="twitter"
                class="cursor-pointer z-10"
                @click="openSocialNetwork('twitter')"
              />
              <BaseIcon
                name="youtube"
                class="cursor-pointer z-10"
                @click="openSocialNetwork('youtube')"
              />
              <BaseIcon
                name="tiktok"
                class="cursor-pointer z-10"
                @click="openSocialNetwork('tiktok')"
              />
            </div>
          </div>
          <div class="flex gap-4 mt-10">
            <div class="w-full">
              <span class="body-text-2 uppercase">About</span>
              <ul class="mt-[13px] list-none body-text-4">
                <li v-for="{ text, value } in about" :key="value">
                  <a
                    :href="getPageUrl(value)"
                    target="_blank"
                    rel="noreferrer"
                    class="cursor-pointer"
                  >
                    {{ text }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full">
              <span class="body-text-2 uppercase">Give</span>
              <ul class="mt-[13px] list-none body-text-4">
                <li v-for="{ text, value } in give" :key="value">
                  <a
                    :href="getPageUrl(value)"
                    target="_blank"
                    rel="noreferrer"
                    class="cursor-pointer"
                  >
                    {{ text }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex gap-4 mt-10">
            <div class="w-full">
              <span class="body-text-2 uppercase">Help</span>
              <ul class="mt-[13px] list-none body-text-4">
                <li v-for="{ text, value } in help" :key="value">
                  <a
                    :href="getPageUrl(value)"
                    target="_blank"
                    rel="noreferrer"
                    class="cursor-pointer"
                  >
                    {{ text }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full">
              <span class="body-text-2 uppercase">Legal</span>
              <ul class="mt-[13px] list-none body-text-4">
                <li v-for="{ text, value } in legal" :key="value">
                  <a
                    :href="getPageUrl(value)"
                    target="_blank"
                    rel="noreferrer"
                    class="cursor-pointer"
                  >
                    {{ text }}
                  </a>
                </li>
              </ul>
              <div
                class="flex flex-col justify-center items-center w-[112px] h-[60px] bg-white mt-2.5"
              >
                <img src="@/assets/bbb.png" alt="BBB Accredited Business" />
                <img
                  src="@/assets/bbb-rating-a-plus.png"
                  alt="BBB Rating A+"
                  class="mt-[3px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10 lg:text-center">
          <span class="detail-3">
            © The CUDDLY Group, Inc. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon.vue'
import socialNetworks from '@/mixins/socialNetworks'
import { PAGES } from '@/constants/externalPages'

export default {
  name: 'DashboardFooter',
  components: { BaseIcon },
  mixins: [socialNetworks],
  data() {
    return {
      isSubscriptionDetailsOpen: false,
      isSubscriptionReactivateOpen: false,
      about: [
        { text: 'Our Team', value: 'ourTeam' },
        // { text: 'Contact', value: 'contact' },
        { text: 'Press', value: 'press' },
        { text: 'Jobs', value: 'jobs' },
        { text: 'Blog', value: 'blog' }
      ],
      give: [
        { text: 'Change A Life', value: 'changeALife' },
        { text: 'Urgent Care', value: 'urgentCare' },
        { text: 'Rescue Wish Lists', value: 'rescueWishlists' },
        { text: 'Almost Funded', value: 'almostFunded' },
        { text: 'Adopt A Pet', value: 'adoptAPet' }
      ],
      help: [
        { text: 'Help Center', value: 'faqs' },
        // { text: 'Order Status', value: 'orderStatus' },
        { text: 'Return Policy', value: 'returnPolicy' },
        { text: 'Contact Us', value: 'contact' }
      ],
      legal: [
        { text: 'Privacy Policy', value: 'privacyPolicy' },
        { text: 'Terms', value: 'terms' }
      ]
    }
  },
  methods: {
    getPageUrl(page) {
      return PAGES[page]
    }
  },
  watch: {
    '$store.state.isSubscriptionDetailsOpen': function (value) {
      this.isSubscriptionDetailsOpen = value
    },
    '$store.state.isSubscriptionReactivateOpen': function (value) {
      this.isSubscriptionReactivateOpen = value
    }
  }
}
</script>

<style>
.footer::after {
  content: '';
  background-size: cover;
  background: url('@/assets/bones.png') center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.2;
}
</style>

export default {
  methods: {
    escapeHandler: function (callback) {
      const handler = (e) => {
        if (e.key === 'Escape') {
          callback()
        }
      }

      document.addEventListener('keydown', handler)
      this.emitter.on('remove-escape-handler', () => {
        document.removeEventListener('keydown', handler)
      })
    }
  },
  beforeUnmount() {
    this.emitter.emit('remove-escape-handler')
  }
}

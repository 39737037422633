<template>
  <div class="flex-1 w-full md:min-w-max mb-2.5 md:mb-5">
    <h4 class="text-brand-navy">Campaigns Donated</h4>
  </div>
  <div class="md:bg-white rounded-t-lg">
    <div class="sm:hidden">
      <BaseDropdown
        id="tabs-dropdown"
        :options="tabs"
        variant="secondary"
        v-model="selectedTab"
        :value="selectedTab"
      />
    </div>
    <div class="hidden sm:block">
      <nav
        class="isolate flex divide-x divide-gray-200 rounded-t-lg shadow cursor-pointer"
        aria-label="Tabs"
      >
        <span
          v-for="(tab, tabIdx) in tabs"
          :key="tab.value"
          :class="[
            tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
            tabIdx === 0 ? 'rounded-l-lg' : '',
            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
            'rounded-b-none group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
          ]"
          @click="changeTab(tab)"
        >
          <span>{{ tab.label }}</span>
          <span
            aria-hidden="true"
            :class="[
              tab.current ? 'bg-brand-pink' : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5'
            ]"
          />
        </span>
      </nav>
    </div>
    <div
      class="flex items-center justify-center w-full bg-white h-40"
      v-if="loading && currentPage === 1"
    >
      <BaseLoader />
    </div>
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 py-10 md:px-[30px] gap-[30px]"
      v-else
    >
      <template v-if="selectedTab === 'success'">
        <DashboardSuccessStoryCard
          v-for="item in data"
          :key="item.id"
          :story="item"
        />
      </template>
      <template v-else>
        <DashboardCampaignCard
          v-for="item in data"
          :key="item.id"
          :type="selectedTab"
          :campaign="item"
        />
      </template>
    </div>
    <div class="flex justify-center w-full pb-10" v-if="canLoadMore">
      <BaseButton
        id="load-more"
        variant="outlined"
        class="w-full md:w-fit py-[17px] px-[66px] text-brand-navy uppercase font-bold text-sm border-secondary-steel leading-14 rounded-[5px] tracking-[5px]"
        @click="loadMore"
        :loading="loading"
      >
        Load More
      </BaseButton>
    </div>
  </div>

  <BaseDialog :open="isShareDialogOpen" @close="handleClose" dismissable>
    <div class="pt-4">
      <div class="w-full flex items-center gap-4">
        <BaseIcon
          id="facebook"
          name="facebook"
          class="h-10 w-10 cursor-pointer"
          @click="handleShareFacebook"
        />
        <BaseIcon
          id="twitter"
          name="twitter"
          class="h-10 w-10 mt-2 cursor-pointer"
          @click="handleShareTwitter"
        />
        <BaseIcon
          id="link"
          name="link"
          class="h-10 w-10 mt-2 cursor-pointer"
          @click="handleShareLink"
        />
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { getCampaignsDonated, getDonorFeed } from '@/api/donor'
import BaseDropdown from '@/components/Base/BaseDropdown.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import DashboardCampaignCard from './DashboardCampaignCard.vue'
import DashboardSuccessStoryCard from './DashboardSuccessStoryCard.vue'
import BaseLoader from '@/components/Base/BaseLoader.vue'
import BaseDialog from '@/components/Base/BaseDialog.vue'
import campaign from '@/mixins/campaign'
import BaseIcon from '@/components/Base/BaseIcon.vue'
import escapeHandler from '@/mixins/escapeHandler'

export default {
  name: 'DashboardCampaignsDonated',
  mixins: [campaign, escapeHandler],
  components: {
    BaseDropdown,
    BaseButton,
    DashboardCampaignCard,
    DashboardSuccessStoryCard,
    BaseLoader,
    BaseDialog,
    BaseIcon
  },
  data() {
    return {
      selectedTab: 'core',
      isShareDialogOpen: false,
      shareUrl: '',
      tabs: [
        {
          id: 'core',
          label: 'Animal Campaigns',
          href: '#',
          current: true,
          value: 'core'
        },
        {
          id: 'pantry',
          label: 'Community Programs',
          href: '#',
          current: false,
          value: 'pantry'
        },
        {
          id: 'success',
          label: 'Success Stories',
          href: '#',
          current: false,
          value: 'success'
        }
      ],
      data: [],
      currentPage: 1,
      canLoadMore: false,
      loading: false
    }
  },
  async beforeMount() {
    this.loading = true
    const [feedPromise, coreCampaignsPromise] = await Promise.allSettled([
      getDonorFeed(),
      getCampaignsDonated({
        page: this.currentPage,
        type: 'core'
      })
    ])
    const { status: feedStatus, data: feedData } = feedPromise.value
    const { status: coreCampaignStatus, data: coreCampaignsData } =
      coreCampaignsPromise.value

    if (feedStatus === 200) {
      const { coreCampaigns, pantryCampaigns, successStories } = feedData
      this.tabs[0].label = `Animal Campaigns (${coreCampaigns})`
      this.tabs[1].label = `Community Programs (${pantryCampaigns})`
      this.tabs[2].label = `Success Stories (${successStories})`
    }

    if (coreCampaignStatus === 200) {
      this.updateData(coreCampaignsData)
    }

    this.loading = false
  },
  created() {
    this.emitter.on('share', (story) => {
      if (story) {
        this.shareUrl = this.getSuccessStoryUrl(story)
        this.isShareDialogOpen = true
      }
    })

    this.escapeHandler(() => {
      this.isShareDialogOpen = false
    })
  },
  methods: {
    async getCoreCampaigns() {
      const { status, data } = await getCampaignsDonated({
        page: this.currentPage,
        type: 'core'
      })

      if (status === 200) {
        this.updateData(data)
      }
    },
    async getPantryCampaigns() {
      const { status, data } = await getCampaignsDonated({
        page: this.currentPage,
        type: 'pantry'
      })

      if (status === 200) {
        this.updateData(data)
      }
    },
    async getSuccessStories() {
      const { status, data } = await getCampaignsDonated({
        page: this.currentPage,
        type: 'stories'
      })

      if (status === 200) {
        this.updateData(data)
      }
    },
    async changeTab(tab) {
      if (this.loading) {
        return
      }

      this.loading = true
      this.tabs.forEach((item) => {
        item.current = false
      })

      const { value } = tab
      tab.current = true
      this.selectedTab = value
      this.currentPage = 1
      this.canLoadMore = false
      this.data = []

      if (value === 'core') {
        await this.getCoreCampaigns()
      } else if (this.selectedTab === 'pantry') {
        await this.getPantryCampaigns()
      } else {
        await this.getSuccessStories()
      }

      this.loading = false
    },
    async loadMore() {
      this.currentPage++
      this.loading = true

      if (this.selectedTab === 'core') {
        await this.getCoreCampaigns()
      } else if (this.selectedTab === 'pantry') {
        await this.getPantryCampaigns()
      } else {
        await this.getSuccessStories()
      }

      this.loading = false
    },
    updateData(data) {
      const { data: results, last_page: lastPage } = data
      this.data = [...this.data, ...results]
      this.canLoadMore = this.currentPage < lastPage
    },
    handleClose() {
      this.isShareDialogOpen = false
      this.shareUrl = ''
    },
    handleShareFacebook() {
      const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.shareUrl
      )}`
      window.open(facebookShare, '_blank', 'noreferrer')
    },
    handleShareTwitter() {
      const twitterShare = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.shareUrl
      )}`
      window.open(twitterShare, '_blank', 'noreferrer')
    },
    handleShareLink() {
      navigator.clipboard.writeText(this.shareUrl)
      this.$toast.success('Copied to clipboard!')
    }
  },
  watch: {
    selectedTab() {
      this.$nextTick(() => {
        this.changeTab(
          this.tabs.find((item) => item.value === this.selectedTab)
        )
      })
    }
  }
}
</script>

<template>
  <div class="flex flex-col p-5 mt-4">
    <h4 class="text-brand-navy font-extrabold leading-45">
      We are sorry to see you go...
    </h4>
    <span
      v-if="this.subscription.method === 'stripe'"
      class="body-text-1 mt-2.5 text-gray-700"
    >
      Do you really want to cancel your future donation to
      {{ subscriptionType }}? Instead, would you like to pause for...
    </span>
    <span v-else class="body-text-1 mt-2.5 text-gray-700">
      Do you really want to cancel your future donation to
      {{ subscriptionType }}? Instead, would you like to pause?
    </span>
    <div
      v-show="this.subscription.method === 'stripe'"
      class="grid grid-cols-3 gap-3 mt-4 mb-[30px]"
    >
      <div
        v-for="option in options"
        :key="option.id"
        class="flex justify-center items-center py-2.5 px-[13px] w-full h-[47px] rounded-[5px] cursor-pointer"
        @click="handleMonths(option.value)"
        :class="{
          'bg-brand-navy text-white': option.value === months,
          'bg-[#F2F2F2] text-black': option.value !== months
        }"
      >
        <h6>{{ option.display }}</h6>
      </div>
    </div>
  </div>
  <div
    class="absolute left-0 right-0 bottom-28 md:bottom-10 flex flex-col w-full px-5 md:px-[26px] items-center"
  >
    <BaseButton
      id="dismiss"
      variant="underline"
      class="uppercase max-w-max mb-[30px] hover:text-brand-navy"
      @click="handleCancel"
    >
      Cancel Subscription
    </BaseButton>
    <BaseButton
      id="save"
      class="py-[14px] flex-1 tracking-1"
      @click="handlePause"
      :loading="loading"
      :disabled="loading"
    >
      Pause Payments
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'
import date from '@/mixins/date'
import { pauseSubscription } from '@/api/subscriptions'

export default {
  name: 'SubscriptionCancelDefault',
  mixins: [date],
  components: {
    BaseButton
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    subscriptionType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      months: 0,
      loading: false,
      options: [
        {
          id: 'pause-1',
          display: '1 Month',
          value: 1
        },
        {
          id: 'pause-3',
          display: '3 Months',
          value: 3
        },
        {
          id: 'pause-6',
          display: '6 Months',
          value: 6
        }
      ]
    }
  },
  methods: {
    handleMonths(value) {
      this.months = value
    },
    handleCancel() {
      this.isCanceling = true
      this.paused = false
      this.emitter.emit('subscription-cancel_canceling', { isCanceling: true })
    },
    async handlePause() {
      if (this.subscription.method === 'stripe' && this.months === 0) {
        this.$toast.error('Please select a number of months to pause.')
        return
      }

      this.loading = true
      const { status, data, errors } = await pauseSubscription({
        monthsToPause: this.months,
        subscriptionId: this.subscription.subscription_id,
        method: this.subscription.method
      })

      if (status >= 400) {
        const error =
          status === 422
            ? errors?.errors
            : 'Unfortunately we have experienced an error. Please try again later.'
        this.$toast.error('Whoops, looks like something went wrong.')
        this.emitter.emit('subscription-cancel_failed', { error })
      } else {
        this.emitter.emit('subscription-cancel_paused', {
          paused: true,
          months: Number(this.months),
          resumesAt: data?.pause_collection?.resumes_at
        })
      }

      this.loading = false
    }
  }
}
</script>

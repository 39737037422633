import dayjs from 'dayjs'

const ALLOWED_UNITS = [
  'day',
  'week',
  'month',
  'year',
  'hour',
  'minute',
  'second',
  'millisecond'
]

export default {
  methods: {
    /**
     * A function that takes in a date and a format and returns a formatted date.
     * @param {Date|string} date - The date to be formatted.
     * @param {string} format - The format to be used. Defaults to 'DD/MM/YYYY'.
     * @returns {string} The formatted date.
     */
    formatDate: function (date, format = 'DD/MM/YYYY') {
      return dayjs(date).format(format)
    },
    /**
     * A function that takes in a date and an object with a unit and amount.
     * @param {(Date|string)} date - The date to be modified.
     * @param {Object} options - An object with a unit and amount.
     * @param {('day'|'week'|'month'|'year'|'hour'|'minute'|'second'|'millisecond')} options.unit - The unit to be used. Defaults to 'day'.
     * @param {number} options.amount - The amount to be added. Defaults to 0.
     * @returns {(string|Date)} The modified date.
     */
    setDate: function (date, { unit = 'day', amount = 0 } = {}) {
      if (!dayjs(date).isValid() || (unit && !ALLOWED_UNITS.includes(unit)))
        return 'Invalid Date'

      return dayjs(date).add(amount, unit)
    }
  }
}

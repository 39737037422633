<template>
  <div
    class="flex w-full h-[172px] md:h-[212px] justify-center items-center bg-secondary-light-blue"
  >
    <div v-if="isFullBowls" class="text-brand-navy text-right">
      <h2 class="font-bold">Full Bowls</h2>
      <h2 class="font-extrabold">Club</h2>
    </div>
    <img
      :alt="subscriptionType"
      src="@/assets/cuddly-club.png"
      class="max-w-[234.51px] max-h-[130px] h-full"
      v-else
    />
  </div>
  <div class="flex flex-col p-5">
    <h4 class="text-brand-navy font-extrabold text-[30px] leading-45 mb-5">
      You are all set!
    </h4>
    <span class="subtitle-1 text-gray-700 mb-[30px] font-normal">
      Thank you for being a loyal member of {{ subscriptionType }}. Your
      subscription has been changed for the next billing period. This update
      will take a few minutes to process. In the meantime, feel free to explore
      more on CUDDLY.
    </span>
  </div>
  <div
    class="w-full absolute left-0 right-0 bottom-28 md:bottom-10 px-5 md:px-[26px]"
  >
    <BaseButton
      id="save"
      class="py-[14px] px-[66px] md:px-[33px] lg:px-[66px] w-full tracking-2"
      @click="handleBack"
      :loading="loading"
      :disabled="loading"
    >
      Done
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  name: 'SubscriptionEditUpdated',
  components: {
    BaseButton
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    subscriptionType: {
      type: String,
      required: true
    },
    isFullBowls: {
      type: Boolean,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    handleBack() {
      this.emitter.emit('back-to-subscription', { reload: true })
    }
  }
}
</script>

<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
  >
    <path
      d="M33.4544 7.20001H6.75025C3.05526 7.20001 0.032074 10.305 0.032074 14.1002V25.3989C0.032074 29.194 3.05519 32.2991 6.75025 32.2991H33.4124C37.1074 32.2991 40.1306 29.1941 40.1306 25.3989L40.1303 14.1002C40.1722 10.3051 37.149 7.20001 33.4541 7.20001H33.4544ZM36.8135 25.3987C36.8135 27.2963 35.302 28.8488 33.4544 28.8488H6.75025C4.90272 28.8488 3.39116 27.2963 3.39116 25.3987V14.1C3.39116 12.2024 4.90272 10.6499 6.75025 10.6499H33.4124C35.26 10.6499 36.7715 12.2024 36.7715 14.1L36.7712 25.3987H36.8135Z"
      fill="#2A415B"
    />
    <path
      d="M9.5212 15.3068C9.5212 16.4977 8.58135 17.463 7.42182 17.463C6.26258 17.463 5.32243 16.4977 5.32243 15.3068C5.32243 14.1158 6.26258 13.1505 7.42182 13.1505C8.58135 13.1505 9.5212 14.1158 9.5212 15.3068Z"
      fill="#FF696E"
    />
    <path
      d="M34.8821 24.1919C34.8821 25.3828 33.942 26.3481 32.7828 26.3481C31.6232 26.3481 30.6834 25.3828 30.6834 24.1919C30.6834 23.001 31.6232 22.0356 32.7828 22.0356C33.942 22.0356 34.8821 23.001 34.8821 24.1919Z"
      fill="#FF696E"
    />
    <path
      d="M20.775 20.7839V22.7245C21.4474 22.5088 21.5313 21.9914 21.5313 21.7327C21.4894 21.3013 21.3211 21.0426 20.775 20.7839Z"
      fill="#FF696E"
    />
    <path
      d="M18.9695 18.2393C18.9695 18.5843 19.1375 18.8 19.6419 19.0154V17.3335C19.2638 17.5062 18.9695 17.8082 18.9695 18.2393Z"
      fill="#FF696E"
    />
    <path
      d="M14.8951 14.4023C11.9979 17.378 11.9979 22.1648 14.8951 25.0973C17.7923 28.073 22.4529 28.073 25.3081 25.0973C28.2053 22.1216 28.2053 17.3348 25.3081 14.4023C22.4531 11.4269 17.7503 11.4269 14.8951 14.4023ZM20.7735 23.976V24.9678L19.6817 24.9681V23.9763C18.842 23.847 18.1282 23.4156 17.6664 22.7688L17.4986 22.5531L18.2542 21.5613L18.5061 21.9064C18.7161 22.1651 19.0939 22.5531 19.6817 22.7258V20.3972C18.7998 20.0952 17.7924 19.6641 17.7924 18.241C17.7924 17.1198 18.548 16.2571 19.6817 16.0847V15.0929H20.7733V16.0847C21.4871 16.2141 22.0749 16.6021 22.5368 17.1628L22.7046 17.3785L21.9068 18.3703L21.6133 18.025C21.3614 17.723 21.0674 17.5076 20.7314 17.3782V19.4051C21.5711 19.6638 22.7049 20.1812 22.7049 21.7337C22.7049 22.6392 22.2011 23.7603 20.7736 23.976L20.7735 23.976Z"
      fill="#FF696E"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDollarColored'
}
</script>

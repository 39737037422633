<template>
  <div class="flex flex-col px-5 md:pb-0 my-[60px] w-full text-center">
    <h4 class="text-brand-navy font-extrabold">Delete Payment</h4>
    <span class="body-text-1 text-brand-navy mt-2.5 px-5">
      Are you sure you want to delete this payment card?
    </span>
    <div class="flex gap-2.5 mt-[30px]">
      <BaseButton
        id="cancel"
        variant="outlined"
        class="tracking-1 py-[15px]"
        @click="handleCancel"
        :disabled="loading"
      >
        Cancel
      </BaseButton>
      <BaseButton
        id="delete"
        class="tracking-1 py-[15px]"
        @click="handleDelete"
        :loading="loading"
        :disabled="loading"
      >
        Delete
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { deleteCards } from '@/api/cards'
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  name: 'SubscriptionPaymentDelete',
  components: { BaseButton },
  props: {
    subscriptionId: {
      type: String,
      required: true
    },
    card: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async handleDelete() {
      this.loading = true
      const payload = {
        customer_id: this.card.customer,
        card_id: this.card.id
      }
      const { status } = await deleteCards(this.subscriptionId, payload)

      if (status >= 400) {
        this.$toast.error('Whoops, looks like something went wrong.')
      } else {
        this.$toast.success('Card deleted successfully.')
        this.emitter.emit('reload-cards')
      }

      this.loading = false
    },
    handleCancel() {
      this.emitter.emit('back-to-subscription', { reload: false })
    }
  }
}
</script>
